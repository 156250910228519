/**
 *
 * Asynchronously loads the component for RankRidePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RankRidePage = lazyLoad(
  () => import('./index'),
  module => module.RankRidePage,
);
