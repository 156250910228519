import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/header';
import { request } from 'utils/request';
import { myGarageActions as actions } from '.';

function* userRides(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/garage/rides/${action.payload}`;

    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(actions.userRidesSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(actions.userRidesError(true));
  }
}

function* userGroups(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/garage/groups/${action.payload}`;

    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(actions.userGroupsSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(actions.userGroupsError(true));
  }
}

function* userBadges(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/garage/badges/${action.payload}`;

    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(actions.userBadgesSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(actions.userBadgesError(true));
  }
}

export function* myGarageSaga() {
  yield takeLatest(actions.userRides.type, userRides);
  yield takeLatest(actions.userGroups.type, userGroups);
  yield takeLatest(actions.userBadges.type, userBadges);
}
