/**
 *
 * Asynchronously loads the component for PhoneChangedPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PhoneChangedPage = lazyLoad(
  () => import('./index'),
  module => module.PhoneChangedPage,
);
