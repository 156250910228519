/**
 *
 * GroupMembers
 *
 */
import { Button } from 'app/components/Button';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { selectGroupData } from '../GroupDetailsPage/slice/selectors';
import {
  selectIsAuthenticated,
  selectUserData,
} from '../HomePage/slice/selectors';
import { InviteModal } from './Components/InviteModal';
import { ManagerModal } from './Components/ManagerModal';
import { MemberModal } from './Components/MemberModal';
import { RequestedModal } from './Components/RequestedModal';
import { SendInvite } from './Components/SendInvite';
import { groupMembersActions } from './slice';
import { groupDetailsActions } from '../GroupDetailsPage/slice';
import {
  selectMembers,
  selectPagination,
  selectShowMore,
} from './slice/selectors';

import FoundingMemberBadge from 'assets/svg/badges/Founding_Member.svg';
import PremierMemberBadge from 'assets/svg/badges/Premier_Member.svg';
import PowerMemberBadge from 'assets/svg/badges/Power_Member.svg';
import CollectorBadge from 'assets/svg/badges/Collector.svg';
import SuperCollectorBadge from 'assets/svg/badges/Super_Collector.svg';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

const getImage = (name: string) => {
  if (name === 'Founding Member') {
    return FoundingMemberBadge;
  }
  if (name === 'Premier Member') {
    return PremierMemberBadge;
  }
  if (name === 'Power Member') {
    return PowerMemberBadge;
  }
  if (name === 'Collector Member') {
    return CollectorBadge;
  }
  if (name === 'Super Collector') {
    return SuperCollectorBadge;
  }
};

interface Props {
  tabCondition: boolean;
}

export const GroupMembers = memo((props: Props) => {
  const { tabCondition } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const members = useSelector(selectMembers);
  let groupData = useSelector(selectGroupData);
  const pagination = useSelector(selectPagination);
  const showMore = useSelector(selectShowMore);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userData = useSelector(selectUserData);

  const [modalData, setModalData]: any = useState(null);
  const [modal, setModal] = useState('');

  useEffect(() => {
    dispatch(
      groupMembersActions.members({
        groupId: id,
        page: pagination.page,
        limit: pagination.limit,
      }),
    );
  }, []);

  const onClose = useCallback(() => {
    setModal('');
    setModalData(null);
    dispatch(groupDetailsActions.group(id));
  }, []);
  const renderMemberType = (member: any) => {
    if (member.role === 'owner') {
      return (
        <td className="text-right">
          <button type="button" className="btn btn--action">
            <i className="fas fa-circle"></i>
            <strong>Owner</strong>
          </button>
        </td>
      );
    }
    if (member.role === 'member') {
      return (
        <td className="text-right">
          <button
            type="button"
            className="btn btn--action"
            onClick={() => {
              if (groupData.isOwner) {
                setModal('memberModal');
                setModalData({
                  group: groupData,
                  member,
                });
              }
            }}
          >
            Member
          </button>
        </td>
      );
    }
    if (member.role === 'manager') {
      return (
        <td className="text-right">
          <button
            type="button"
            className="btn btn--action"
            onClick={() => {
              if (groupData.isOwner) {
                setModal('managerModal');
                setModalData({
                  group: groupData,
                  member,
                });
              }
            }}
          >
            <i className="fas fa-circle"></i>
            <strong>Manager</strong>
          </button>
        </td>
      );
    }

    if (member.role === 'requested') {
      return (
        <td className="text-right">
          <button
            type="button"
            className="btn btn--action"
            onClick={() => {
              if (groupData.isOwner || groupData.isManager) {
                setModal('requestModal');
                setModalData({
                  group: groupData,
                  member,
                });
              }
            }}
          >
            <i className="fas fa-circle"></i>
            Requested
          </button>
        </td>
      );
    }
    if (member.role === 'invited') {
      return (
        <td className="text-right">
          <button
            type="button"
            className="btn btn--action"
            onClick={() => {
              setModal('invitedModal');
              setModalData({
                group: groupData,
                member,
              });
            }}
          >
            Invited
          </button>
        </td>
      );
    }
  };

  const renderImage = (badge: any) => {
    if (badge) {
      let image = getImage(badge.name);
      if (image) {
        return (
          <div
            className="badge badge--thumbnail"
            data-tip
            data-for={badge?._id}
          >
            <img
              src={image}
              alt={badge.name}
              className="badge__icon"
              style={{
                padding: '0.1rem',
                objectFit: 'contain',
                width: '100%',
                cursor: 'pointer',
              }}
            />
            <ReactTooltip place="top" effect="solid" id={badge?._id}>
              <span>{badge.name}</span>
            </ReactTooltip>
          </div>
        );
      }
      return <div className="badge no-badge--thumbnail"></div>;
    }
    return <div className="badge no-badge--thumbnail"></div>;
  };

  const renderBadges = badges => {
    let tempRandomValue = Math.floor(Math.random() * 10000);

    return (
      <div className="car__badges">
        {renderImage(badges[0])}
        {badges.length > 1 && (
          <div
            className="rank rank--increase"
            data-tip
            data-for={`show-${tempRandomValue}`}
          >
            +{badges.length - 1}
            <ReactTooltip
              place="top"
              effect="solid"
              id={`show-${tempRandomValue}`}
            >
              {badges.slice(1).map(badge => (
                <>
                  <span>{badge.name}</span>
                  <br />
                </>
              ))}
            </ReactTooltip>
          </div>
        )}
      </div>
    );
  };

  const renderMembers = () => {
    return members.data.map(member => {
      return (
        <tr key={member?._id}>
          <td>
            <div className="group-user">
              {/*{renderImage(member.user.badges[0])}*/}
              {renderBadges(member.user.badges)}
              {member?.user?._id === userData?._id ? (
                <Link to="/my-garage">{member.user.username}</Link>
              ) : (
                <Link to={`/garage/${member.user?._id}`}>
                  {member.user.username}
                </Link>
              )}
            </div>
          </td>
          {renderMemberType(member)}
        </tr>
      );
    });
  };

  const renderShowMore = () => {
    if (showMore.loading) {
      return (
        <tr>
          <td colSpan={3} className="show-more">
            <button
              type="button"
              className="btn btn--secondary btn--full-width btn--show-more-bottom"
            >
              Loading
            </button>
          </td>
        </tr>
      );
    }
    if (pagination.totalPages - pagination.page > 1) {
      return (
        <tr>
          <td colSpan={3} className="show-more">
            <button
              type="button"
              className="btn btn--secondary btn--full-width btn--show-more-bottom btn--show-more-bottom-down"
              onClick={() => {
                dispatch(
                  groupMembersActions.showMore({
                    groupId: id,
                    page: pagination.page + 1,
                    limit: pagination.limit,
                  }),
                );
              }}
            >
              Show More
            </button>
          </td>
        </tr>
      );
    }
  };

  const renderBody = () => {
    if (members.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan={3}>Loading</td>
          </tr>
        </tbody>
      );
    }
    if (members.error) {
      return <div>Error</div>;
    }
    return (
      <tbody>
        {renderMembers()}
        {renderShowMore()}
      </tbody>
    );
  };

  const renderInvitedButton = () => {
    if (groupData.isOwner || groupData.isManager) {
      return (
        <Button
          className="btn btn--primary-gradient"
          onClick={() => {
            setModal('sendInviteModal');
          }}
        >
          Invite
        </Button>
      );
    }
    return null;
  };

  return (
    <div className="tab-content__pane" id="members">
      <div
        className="prompt"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          Click the button in the Stauts column to manage a request or view
          member details.
        </div>
        {renderInvitedButton()}
      </div>

      <table className="data-table data-table--full-width">
        <thead>
          <tr>
            <th>Member</th>
            <th className="text-right">Member Status / Action</th>
          </tr>
        </thead>
        {renderBody()}
      </table>
      <SendInvite
        groupId={id || ''}
        groupName={groupData.name}
        active={modal === 'sendInviteModal'}
        onClose={onClose}
      />
      <ManagerModal
        modalData={modalData}
        active={modal === 'managerModal'}
        onClose={onClose}
      />
      <MemberModal
        modalData={modalData}
        active={modal === 'memberModal'}
        onClose={onClose}
      />
      <InviteModal
        modalData={modalData}
        active={modal === 'invitedModal'}
        onClose={onClose}
      />
      <RequestedModal
        modalData={modalData}
        active={modal === 'requestModal'}
        onClose={onClose}
      />
    </div>
  );
});
