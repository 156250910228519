/**
 *
 * Asynchronously loads the component for PasswordChangedPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PasswordChangedPage = lazyLoad(
  () => import('./index'),
  module => module.PasswordChangedPage,
);
