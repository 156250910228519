import {
  selectIsAuthenticated,
  selectUser,
} from 'app/pages/HomePage/slice/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PublicRoute = (props: any) => {
  const { component: Component } = props;
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUser);

  if (!isAuthenticated) {
    return <Component {...props} />;
  }

  if (!user.data.isEmailVerified) {
    return <Navigate to="/register/verify" />;
  }

  return <Navigate to={'/'} />;
};

export { PublicRoute };
