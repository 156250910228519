/**
 *
 * Button
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button = memo((props: Props) => {
  const { children, className, disabled, ...rest } = props;
  return (
    <ButtonStyled
      className={`${className || 'btn btn--primary-gradient btn--small'} ${
        disabled ? 'btn--disabled' : ''
      }`}
      disabled={disabled}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
});

const ButtonStyled = styled.button``;
