/**
 *
 * Breadcrumb
 *
 */
import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  links?: Array<any>;
}

export function Breadcrumb(props: Props) {
  const { links: linksProps } = props;
  const links = [
    {
      label: 'Home',
      to: '/',
    },
    ...(linksProps || []),
  ];
  const renderLinks = () => {
    return links.map((link, index) => {
      if (link.loading) {
        return null;
      }
      return (
        <li key={link.label} className="breadcrumb__item">
          <Link to={link.to}>{link.label}</Link>
        </li>
      );
    });
  };
  return (
    <nav className="breadcrumb">
      <ul className="breadcrumb__list">{renderLinks()}</ul>
    </nav>
  );
}
