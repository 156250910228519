import { Modal } from 'app/components/Modal';
import React, { memo } from 'react';

interface Props {
  active: boolean;
  onClose: any;
  onConfirm: any;
}

export const ConfirmLeaveGroupModal = memo((props: Props) => {
  const { active, onClose, onConfirm } = props;

  return (
    <Modal active={active} onClose={onClose}>
      <div className="modal__body">
        <p>
          Are you sure you want to promote this person as Owner and leave the
          group?
        </p>
      </div>
      <div className="modal__actions_leave_group">
        <button
          type="button"
          className="btn btn--primary-gradient"
          style={{
            padding: `0px 40px`,
          }}
          onClick={() => {
            onConfirm();
          }}
        >
          Yes
        </button>
        <button
          type="button"
          className="btn btn--primary-gradient"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
});
