/**
 *
 * ShopRidePage
 *
 */
import { Breadcrumb } from 'app/components/Breadcrumb';
import React, { memo } from 'react';
import car1 from 'assets/images/shop_car_1.jpg';
import car2 from 'assets/images/shop_car_2.jpg';
import car3 from 'assets/images/shop_car_3.jpg';
import { Link } from 'react-router-dom';

interface Props {}

export const ShopRidePage = memo((props: Props) => {
  return (
    <main className="main">
      <Breadcrumb />
      <section>
        <h2>Looking for your next ride?</h2>

        <div className="card-list">
          <div className="card card--plain">
            <div className="card__img">
              <img src={car1} alt="Car" />
            </div>
            <p className="card__content">
              <strong>See which rides are for sale</strong> Wherever you go on
              the site, where they're <strong>located</strong>, whether the
              owner has a <strong>price</strong> in mind, and other exclusive
              buyer info.
            </p>
          </div>

          <div className="card card--plain">
            <div className="card__img">
              <img src={car2} alt="Car" />
            </div>
            <p className="card__content">
              <strong>Search and filter</strong> to find owners who'd consider
              selling. Find the <strong>exact vehicle</strong> you want, right
              down to the <strong>trim level</strong>, and in a{' '}
              <strong>location</strong> you can get to.
            </p>
          </div>

          <div className="card card--plain">
            <div className="card__img">
              <img src={car3} alt="Car" />
            </div>
            <p className="card__content">
              <strong>Get in touch with the owner</strong> to learn more about
              the ride, and you and the owner can{' '}
              <strong>take it from there</strong> if you're interested.
            </p>
          </div>
        </div>

        <hr className="spacer spacer--vertical" />

        <p className="text-center">
          <Link to="/browse-rides">
            <button
              type="button"
              className="btn btn--primary-gradient btn--small btn--full-width"
              data-href="browse_rides.html"
            >
              Browse Rides
            </button>
          </Link>
        </p>
      </section>
    </main>
  );
});
