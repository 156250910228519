/**
 *
 * Detail
 *
 */
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectGroupData } from '../../slice/selectors';
import moment from 'moment';
import PowerGroup from 'assets/svg/badges/Power_Group.svg';
import PremierGroup from 'assets/svg/badges/Premier_Group.svg';

interface Props {}

export const Detail = memo((props: Props) => {
  const groupData = useSelector(selectGroupData);

  const renderAnnouncement = () => {
    if (groupData.announcement && groupData.isMember) {
      return (
        <>
          <h3>Announcement</h3>
          <p>{groupData.announcement}</p>
        </>
      );
    }
  };

  const getImage = (name: string) => {
    if (name === 'Power Group') {
      return PowerGroup;
    }
    if (name === 'Premier Group') {
      return PremierGroup;
    }
    return null;
  };
  const renderImageAchivement = (badge: any) => {
    if (badge) {
      let image = getImage(badge.name);
      if (image) {
        return (
          <img
            src={image}
            alt={badge.name}
            className="badge__icon"
            style={{
              padding: '0.1rem',
              objectFit: 'contain',
              width: '100%',
            }}
          />
        );
      }
      return null;
    }
    return null;
  };

  const renderBadges = (badges: any) => {
    return badges.map(badge => {
      return (
        <div className="badge" key={badge._id}>
          <div className="badge__image">{renderImageAchivement(badge)}</div>
          <div className="badge__name"> {badge.title}</div>
          <div className="badge__description" style={{ whiteSpace: 'nowrap' }}>
            {badge.description}
          </div>
        </div>
      );
    });
  };

  const renderBadgesWrapper = () => {
    let badges: any = [];

    for (let i = 0; i < groupData.badges.length; i++) {
      badges.push(groupData.badges[i]);
    }

    if (badges.length === 0) {
      return <div></div>;
    }
    if (badges.length === 1) {
      return <div className="badges single">{renderBadges(badges)}</div>;
    }
    return <div className="badges">{renderBadges(badges)}</div>;
  };

  return (
    <div className="tab-content__pane active" id="group">
      <div className="columns">
        <div className="col">
          <div className="data-label">Created:</div>
          <div className="data-value">
            {moment(groupData.createdAt).format('MMMM DD, yyyy')}
          </div>
        </div>
        <div className="col">
          <div className="data-label">Owner:</div>
          <div className="data-value">{groupData.groupOwner.username}</div>
        </div>
        <div className="col">
          <div className="data-label">Membership:</div>
          <div className="data-value">{groupData.membership.title}</div>
        </div>
      </div>
      {renderAnnouncement()}
      {renderBadgesWrapper()}
    </div>
  );
});
