/**
 *
 * RequestedModal
 *
 */
import { Modal } from 'app/components/Modal';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupMembersActions } from '../../slice';
import {
  selectIgnoreRequest,
  selectPromoteToMember,
} from '../../slice/selectors';

interface Props {
  active: boolean;
  onClose: any;
  modalData: any;
}

export const RequestedModal = memo((props: Props) => {
  const { active, onClose, modalData } = props;

  const dispatch = useDispatch();
  const promoteToMember = useSelector(selectPromoteToMember);
  const ignoreRequest = useSelector(selectIgnoreRequest);

  useEffect(() => {
    if (promoteToMember.success) {
      dispatch(groupMembersActions.resetPromoteMember());
      onClose();
    }
  }, [promoteToMember]);

  useEffect(() => {
    if (ignoreRequest.success) {
      dispatch(groupMembersActions.resetIgnoreRequest());
      onClose();
    }
  }, [ignoreRequest]);

  return (
    <Modal active={active} onClose={onClose}>
      <div className="modal__header">
        <h4 className="group__user">
          {modalData && modalData.member.user.username}
        </h4>
        <div className="group__user-status">Requested</div>
      </div>
      <div className="modal__actions">
        <button
          type="button"
          className="btn btn--primary-gradient btn--small"
          onClick={() => {
            dispatch(
              groupMembersActions.promoteToMember({
                groupId: modalData.group._id,
                memberId: modalData.member._id,
              }),
            );
          }}
        >
          {promoteToMember.loading ? 'Loading...' : 'Promote to Member'}
        </button>
        <button
          type="button"
          className="btn btn--secondary-gradient btn--small"
          onClick={() => {
            dispatch(
              groupMembersActions.ignoreRequest({
                groupId: modalData.group._id,
                memberId: modalData.member._id,
              }),
            );
          }}
        >
          {ignoreRequest.loading ? 'Loading...' : 'Ignore Request'}
        </button>
      </div>
    </Modal>
  );
});
