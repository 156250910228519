import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { manageGroupSaga } from './saga';
import { ManageGroupState } from './types';

export const initialState: ManageGroupState = {
  manageGroup: {
    loading: false,
    success: null,
    error: null,
  },
  deleteGroup: {
    loading: false,
    success: null,
    error: null,
  },
};

const slice = createSlice({
  name: 'manageGroup',
  initialState,
  reducers: {
    manageGroup(state, action: PayloadAction<any>) {
      state.manageGroup.loading = true;
      state.manageGroup.error = null;
      state.manageGroup.success = false;
    },
    manageGroupSuccess(state, action: PayloadAction<any>) {
      state.manageGroup.loading = false;
      state.manageGroup.success = action.payload;
    },
    manageGroupError(state, action: PayloadAction<any>) {
      state.manageGroup.loading = false;
      state.manageGroup.error = action.payload;
      state.manageGroup.success = false;
    },
    resetManageGroup(state) {
      state.manageGroup = initialState.manageGroup;
    },
    deleteGroup(state, action: PayloadAction<any>) {
      state.deleteGroup.loading = true;
      state.deleteGroup.error = null;
      state.deleteGroup.success = false;
    },
    deleteGroupSuccess(state, action: PayloadAction<any>) {
      state.deleteGroup.loading = false;
      state.deleteGroup.success = action.payload;
    },
    deleteGroupError(state, action: PayloadAction<any>) {
      state.deleteGroup.loading = false;
      state.deleteGroup.error = action.payload;
      state.deleteGroup.success = false;
    },
    resetDeleteGroup(state) {
      state.deleteGroup = initialState.manageGroup;
    },
  },
});

export const { actions: manageGroupActions } = slice;

export const useManageGroupSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: manageGroupSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useManageGroupSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
