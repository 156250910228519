import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { createGroupActions as actions } from '.';
import { header } from '../../../../utils/header';

function* createGroup(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/groups`;
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify(action.payload),
    });
    yield put(actions.createGroupSuccess(response));
  } catch (error) {
    yield put(actions.createGroupError(error));
  }
}

export function* createGroupSaga() {
  yield takeLatest(actions.createGroup.type, createGroup);
}
