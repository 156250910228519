import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/header';
import { request } from 'utils/request';
import { homePageActions as actions } from '.';

function* getUser() {
  try {
    let token = header();
    if (token) {
      let requestURL = `/api/v1/auth/user`;
      let response = yield call(request, requestURL, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      yield put(actions.getUserSuccess(response));
    } else {
      yield put(actions.getUserError(true));
    }
  } catch (error) {
    yield put(actions.getUserError(true));
  }
}

function* searchZip(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/location?zipCode=${action.payload}`;

    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(actions.searchZipSuccess(response));
  } catch (err: any) {
    console.log(err);
    if (err.response) {
      let error = yield err.response.json();
      if (error.message) {
        yield put(actions.searchZipError(error.message));
      }
    } else {
      yield put(actions.searchZipError('Something went wrong'));
    }
  }
}

export function* homePageSaga() {
  yield takeLatest(actions.getUser.type, getUser);
  yield takeLatest(actions.searchZip.type, searchZip);
}
