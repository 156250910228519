import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { groupDetailsSaga } from './saga';
import { GroupDetailsState } from './types';

export const initialState: GroupDetailsState = {
  group: {
    loading: true,
    data: {},
    error: null,
  },
  joinGroup: {
    loading: false,
    success: null,
    error: null,
  },
  leaveGroup: {
    loading: false,
    success: null,
    error: null,
  },
  requestGroup: {
    loading: false,
    success: null,
    error: null,
  },
  acceptInvite: {
    loading: false,
    success: null,
    error: null,
  },
  members: {
    loading: false,
    data: {},
    error: null,
  },
  promoteToOwner: {
    loading: false,
    success: null,
    error: null,
  },
  alert: {
    loading: false,
    success: null,
    error: null,
  },
};

const slice = createSlice({
  name: 'groupDetails',
  initialState,
  reducers: {
    alert(state) {
      state.alert.loading = true;
      state.alert.success = false;
      state.alert.error = false;
    },
    addAlert(state, action: PayloadAction<any>) {
      state.alert.loading = false;
      state.alert.success = action.payload;
      state.alert.error = false;
    },
    resetAlert(state) {
      state.alert.loading = false;
      state.alert.success = null;
      state.alert.error = null;
    },
    group(state, action: PayloadAction<any>) {
      state.group.loading = true;
      state.group.error = null;
      state.group.data = {};
    },
    setGroup(state, action: PayloadAction<any>) {
      state.group.loading = false;
      state.group.data = {
        ...state.group.data,
        ...action.payload,
      };
    },
    groupSuccess(state, action: PayloadAction<any>) {
      state.group.data = action.payload;
      state.group.loading = false;
    },
    groupError(state, action: PayloadAction<any>) {
      state.group.loading = false;
      state.group.error = action.payload;
      state.group.data = {};
    },
    joinGroup(state, action: PayloadAction<any>) {
      state.joinGroup.loading = true;
      state.joinGroup.error = false;
      state.joinGroup.success = false;
    },
    joinGroupSuccess(state, action: PayloadAction<any>) {
      state.joinGroup.success = action.payload;
      state.joinGroup.loading = false;
    },
    joinGroupError(state, action: PayloadAction<any>) {
      state.joinGroup.loading = false;
      state.joinGroup.error = action.payload;
      state.joinGroup.success = false;
    },
    resetJoinGroup(state) {
      state.joinGroup.loading = false;
      state.joinGroup.error = false;
      state.joinGroup.success = false;
    },
    leaveGroup(state, action: PayloadAction<any>) {
      state.leaveGroup.loading = true;
      state.leaveGroup.error = false;
      state.leaveGroup.success = false;
    },
    leaveGroupSuccess(state, action: PayloadAction<any>) {
      state.leaveGroup.success = action.payload;
      state.leaveGroup.loading = false;
    },
    leaveGroupError(state, action: PayloadAction<any>) {
      state.leaveGroup.loading = false;
      state.leaveGroup.error = action.payload;
      state.leaveGroup.success = false;
    },
    resetLeaveGroup(state) {
      state.leaveGroup.loading = false;
      state.leaveGroup.error = false;
      state.leaveGroup.success = false;
    },
    requestGroup(state, action: PayloadAction<any>) {
      state.requestGroup.loading = true;
      state.requestGroup.error = false;
      state.requestGroup.success = false;
    },
    requestGroupSuccess(state, action: PayloadAction<any>) {
      state.requestGroup.success = action.payload;
      state.requestGroup.loading = false;
    },
    requestGroupError(state, action: PayloadAction<any>) {
      state.requestGroup.loading = false;
      state.requestGroup.error = action.payload;
      state.requestGroup.success = false;
    },
    resetRequestGroup(state) {
      state.requestGroup.loading = false;
      state.requestGroup.error = false;
      state.requestGroup.success = false;
    },
    acceptInvite(state, action: PayloadAction<any>) {
      state.acceptInvite.loading = true;
      state.acceptInvite.error = false;
      state.acceptInvite.success = false;
    },
    acceptInviteSuccess(state, action: PayloadAction<any>) {
      state.acceptInvite.success = action.payload;
      state.acceptInvite.loading = false;
    },
    acceptInviteError(state, action: PayloadAction<any>) {
      state.acceptInvite.loading = false;
      state.acceptInvite.error = action.payload;
      state.acceptInvite.success = false;
    },
    resetAcceptInvite(state) {
      state.acceptInvite.loading = false;
      state.acceptInvite.error = false;
      state.acceptInvite.success = false;
    },
    getMembers(state, action: PayloadAction<any>) {
      state.members.loading = true;
      state.members.error = false;
      state.members.data = [];
    },
    getMembersSuccess(state, action: PayloadAction<any>) {
      state.members.data = action.payload;
      state.members.loading = false;
    },
    getMembersError(state, action: PayloadAction<any>) {
      state.members.loading = false;
      state.members.error = action.payload;
      state.members.data = [];
    },
    promoteToOwner(state, action: PayloadAction<any>) {
      state.promoteToOwner.loading = true;
      state.promoteToOwner.error = false;
      state.promoteToOwner.success = false;
    },
    promoteToOwnerSuccess(state, action: PayloadAction<any>) {
      state.promoteToOwner.loading = false;
      state.promoteToOwner.error = false;
      state.promoteToOwner.success = true;
    },
    promoteToOwnerError(state, action: PayloadAction<any>) {
      state.promoteToOwner.loading = false;
      state.promoteToOwner.error = true;
      state.promoteToOwner.success = false;
    },
  },
});

export const { actions: groupDetailsActions } = slice;

export const useGroupDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: groupDetailsSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGroupDetailsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
