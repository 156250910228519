/**
 *
 * Asynchronously loads the component for SignUpVerifyPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const SignUpVerifyPage = lazyLoad(
  () => import('./index'),
  module => module.SignUpVerifyPage,
);
