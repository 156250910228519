/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 *
 * GroupsPage
 *
 */
import { Breadcrumb } from 'app/components/Breadcrumb';
import React, { memo, useEffect, useState } from 'react';
import iconGroup from 'assets/svg/icon_group.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGroups,
  selectPagination,
  selectShowMore,
} from './slice/selectors';
import { alert } from '../GroupDetailsPage/slice/selectors';
import { groupActions, useGroupSlice } from './slice';
import { Link } from 'react-router-dom';
import {
  selectIsAuthenticated,
  selectUserData,
} from '../HomePage/slice/selectors';
import PowerGroup from 'assets/svg/badges/Power_Group.svg';
import PremierGroup from 'assets/svg/badges/Premier_Group.svg';
import { UserGroups } from '../MyGaragePage/Components/UserGroups';
import { useMyGarageSlice } from '../MyGaragePage/slice';
import ReactTooltip from 'react-tooltip';
import { selectGroupData } from '../GroupDetailsPage/slice/selectors';

interface Props {}

const getImage = (name: string) => {
  if (name === 'Power Group') {
    return PowerGroup;
  }
  if (name === 'Premier Group') {
    return PremierGroup;
  }
  return null;
};

export const GroupsPage = memo((props: Props) => {
  useGroupSlice();
  useMyGarageSlice();
  const dispatch = useDispatch();
  const groups = useSelector(selectGroups);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const pagination = useSelector(selectPagination);
  const showMore = useSelector(selectShowMore);
  const userData = useSelector(selectUserData);
  const addAlert = useSelector(alert);
  const groupData = useSelector(selectGroupData);
  const [search, setSearch] = useState('');

  const fetchData = page => {
    dispatch(
      groupActions.showMore({
        search: search,
        page: page,
        limit: pagination.limit,
      }),
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    if (pagination.page === 0) {
      dispatch(
        groupActions.groups({
          search: search,
          page: pagination.page,
          limit: pagination.limit,
        }),
      );
    }
  }, []);

  const onEnter = (value: any) => {
    dispatch(
      groupActions.groups({
        search: value,
        page: 0,
        limit: pagination.limit,
      }),
    );
  };

  const renderImage = (badge: any) => {
    if (badge) {
      let image = getImage(badge.name);
      if (image) {
        return (
          <div
            className="badge badge--thumbnail"
            data-tip
            data-for={badge?._id}
          >
            <img
              src={image}
              alt={badge.name}
              className="badge__icon"
              style={{
                padding: '0.1rem',
                objectFit: 'contain',
                width: '100%',
                cursor: `pointer`,
              }}
            />
            <ReactTooltip place="top" effect="solid" id={badge?._id}>
              <span>{badge.name}</span>
            </ReactTooltip>
          </div>
        );
      }
      return <div className="badge no-badge--thumbnail"></div>;
    }
    return <div className="badge no-badge--thumbnail"></div>;
  };

  const renderGroups = () => {
    return groups.data.map((group, index) => (
      <tr key={index}>
        <td
          className="rank-group"
          style={{ width: '13.4%', minWidth: '100px !important' }}
        >
          <div
            style={{
              display: 'flex',
              flexWrap: 'nowrap',
              flexDirection: 'row-reverse',
              width: 'fit-content',
            }}
          >
            {renderImage(group.badges[0])}
            <span className="">{group.rank}</span>
          </div>
        </td>
        <td>
          <Link to={`/groups/${group._id}`}>{group.name}</Link>
        </td>
        <td className="text-right">
          {group.members && group.members.count ? group.members.count : 1}
        </td>
      </tr>
    ));
  };

  //  const renderShowMore = () => {
  //    if (showMore.loading) {
  //      return (
  //        <tr>
  //          <td colSpan={3} className="show-more">
  //            <button
  //              type="button"
  //              className="btn btn--secondary btn--full-width btn--show-more-bottom"
  //            >
  //              Loading
  //            </button>
  //          </td>
  //        </tr>
  //      );
  //    }
  //    if (pagination.totalPages - pagination.page > 1) {
  //      return (
  //        <tr>
  //          <td colSpan={3} className="show-more">
  //            <button
  //              type="button"
  //              className="btn btn--secondary btn--full-width btn--show-more-bottom btn--show-more-bottom-down"
  //              onClick={() => {
  //                dispatch(
  //                  groupActions.showMore({
  //                    search: search,
  //                    page: pagination.page + 1,
  //                    limit: pagination.limit,
  //                  }),
  //                );
  //              }}
  //            >
  //              Show More
  //            </button>
  //          </td>
  //        </tr>
  //      );
  //    }
  //  };

  const renderShowMore = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.5rem',
          margin: '2rem auto',
          maxWidth: '30rem',
        }}
      >
        <div
          role={'presentation'}
          style={
            pagination.page > 0
              ? {
                  color: '#fff',
                  cursor: 'pointer',
                  flexShrink: '0',
                }
              : { flexShrink: '0' }
          }
          onClick={() => {
            if (pagination.page > 0) {
              fetchData(pagination.page - 1);
            }
          }}
        >
          <i className="fa fa-chevron-left"></i>
          &nbsp; Prev
        </div>
        <div className="btn" role={'presentation'}>
          <select
            style={{ color: 'grey', flexShrink: '0' }}
            className="form__select"
            onChange={e => fetchData(parseFloat(e.target.value))}
            value={pagination.page}
          >
            {[...Array(pagination.totalPages).fill(10)].map((_, index) => {
              return (
                <option key={`Page ${index}`} value={index}>
                  Page {index + 1}
                </option>
              );
            })}
          </select>
        </div>
        <div
          role={'presentation'}
          style={
            pagination.page < pagination.totalPages - 1
              ? {
                  color: '#fff',
                  cursor: 'pointer',
                  flexShrink: '0',
                }
              : { flexShrink: '0' }
          }
          onClick={() => {
            if (pagination.page < pagination.totalPages - 1) {
              fetchData(pagination.page + 1);
            }
          }}
        >
          Next &nbsp;
          <i className="fa fa-chevron-right"></i>
        </div>
      </div>
    );
  };

  const renderGroupsBody = () => {
    if (groups.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan={3}>Loading</td>
          </tr>
        </tbody>
      );
    }
    if (groups.error) {
      return (
        <tbody>
          <tr>
            <td colSpan={3}>Error Occured</td>
          </tr>
        </tbody>
      );
    }
    if (groups.data.length === 0) {
      return (
        <tbody>
          <tr>
            <td colSpan={3}>No Groups Found</td>
          </tr>
        </tbody>
      );
    }
    return (
      <>
        <tbody>{renderGroups()}</tbody>
        {/*{renderShowMore()}*/}
      </>
    );
  };

  const renderCreateGroup = () => {
    if (!userData?.isEmailVerified && !userData?.isPhoneVerified) {
      return null;
    }
    return (
      <div className="card-list card-list--profile card-list--centered">
        <div className="card">
          <div className="card__header">
            <div className="card__icon">
              <img src={iconGroup} alt="Group" />
            </div>
          </div>
          <p className="text-muted">
            Don't see what you're looking for? Create a group of your own!
          </p>
          <div className="card__cta">
            <Link
              to={
                isAuthenticated && userData.isEmailVerified
                  ? '/groups/create'
                  : '/login'
              }
              className="btn btn--primary-gradient btn--small"
            >
              {isAuthenticated ? 'Create a Group' : 'Sign In to Create a Group'}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const renderYourGroups = () => {
    if (isAuthenticated) {
      return <UserGroups userId={userData._id} fromGroupsPage={true} />;
    }
    return null;
  };
  return (
    <main className="main">
      <div className={`acknowledgment ${addAlert?.success ? 'show' : ''}`}>
        <div className="acknowledgment__text">
          You have left the group {groupData?.name}.
        </div>
      </div>
      <Breadcrumb />

      <section>
        <h2 className="text-center">Groups</h2>
        <p className="prompt text-center">
          Join a group to meet and chat with other drivers and find local
          meetups.
        </p>
        {renderYourGroups()}

        <div className="card card--form card--search">
          <div className="form" data-bitwarden-watching="1">
            <div className="form__group">
              <label htmlFor="search" className="form__label">
                Search Groups
              </label>
              <input
                type="search"
                id="search"
                className="form__input search_group"
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.keyCode === 13 || e.key === 'Enter') {
                    onEnter(e.currentTarget.value);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <table className="data-table">
          <thead>
            <tr>
              <th className="sort">
                <span>Rank</span>
              </th>
              <th>
                <span>Name</span>
              </th>
              <th className="text-right">
                <span>Members</span>
              </th>
            </tr>
          </thead>
          {renderGroupsBody()}
        </table>
        {renderShowMore()}

        {/* <!-- New Group --> */}
        {/* <!-- Choose your make --> */}
        {renderCreateGroup()}
      </section>
    </main>
  );
});
