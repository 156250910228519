import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.groupsChat || initialState;

export const selectMessages = createSelector(
  [selectSlice],
  state => state.messages,
);

export const selectCreateMessage = createSelector(
  [selectSlice],
  state => state.createMessage,
);

export const selectMessagesPagination = createSelector(
  [selectSlice],
  state => state.pagination,
);
export const selectMessagesShowMore = createSelector(
  [selectSlice],
  state => state.showMore,
);
