/**
 *
 * ManageGroupPage
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { manageGroupActions, useManageGroupSlice } from './slice';
import { Breadcrumb } from 'app/components/Breadcrumb';
import { selectManageGroup } from './slice/selectors';
import { selectGroup } from '../GroupDetailsPage/slice/selectors';
import { groupDetailsActions } from '../GroupDetailsPage/slice';
import { selectMemberships } from '../GroupsPage/slice/selectors';
import {
  selectIsAuthenticated,
  selectUserData,
} from '../HomePage/slice/selectors';
import { ConfirmDeleteGroupModal } from './ConfirmDeleteGroupModal';

interface Props {}

const schema = yup.object({
  name: yup.string().required('Name is required'),
});

export const ManageGroupPage = memo((props: Props) => {
  useManageGroupSlice();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      membership: 'open',
      description: '',
      announcement: '',
    },
    resolver: yupResolver(schema),
  });
  const [modal, setModal] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const manageGroup = useSelector(selectManageGroup);
  const group = useSelector(selectGroup);
  const { id } = useParams();
  const memberships = useSelector(selectMemberships);
  const [groupName, setGroupName] = useState(group.data.name);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUserData);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user.isEmailVerified) {
      navigate('/login');
    }
  }, [user]);

  useEffect(() => {
    if (!group.data.isOwner) {
      navigate(`/groups/${id}`);
    }
  }, [group]);

  useEffect(() => {
    if (!group.data.name) {
      dispatch(groupDetailsActions.group(id));
    }
  }, []);
  useEffect(() => {
    if (group.data.name) {
      setGroupName(group.data.name);
    }
  }, [group.data.name]);

  useEffect(() => {
    if (group.data && group.data.name && group.loading === false) {
      reset({
        name: group.data.name,
        membership: group.data.membership.name,
        description: group.data.description,
        announcement: group.data.announcement,
      });
    }
  }, [group]);

  useEffect(() => {
    if (manageGroup.success) {
      dispatch(groupDetailsActions.setGroup(manageGroup.success));
      dispatch(manageGroupActions.resetManageGroup());
      navigate(`/groups/${id}`);
    }
  }, [manageGroup]);

  const onSubmit = (data: any) => {
    dispatch(
      manageGroupActions.manageGroup({
        id,
        data,
      }),
    );
  };

  const renderRadios = () => {
    if (memberships.loading) {
      return <>Loading</>;
    }
    if (memberships.error) {
      return <>Error</>;
    }
    return memberships.data.map(option => (
      <div className="form-control form-control--radio" key={option.name}>
        <input
          {...register('membership')}
          type="radio"
          className="custom-radio"
          id={option.name}
          value={option.name}
          name="membership"
        />
        <label htmlFor={option.name}>
          {option.title} -
          <span className="text-muted text-small">{option.description}</span>
        </label>
      </div>
    ));
  };

  const renderSection = () => {
    if (group.loading) {
      return <div>Loading...</div>;
    }
    if (group.error) {
      return <div>Error</div>;
    }
    return (
      <section>
        <h2 className="text-center">Create Group</h2>
        <div className="card card--form card--login">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__group">
              <label htmlFor="groupName" className="form__label">
                Name
              </label>
              <input
                type="groupName"
                id="groupName"
                className="form__input"
                {...register('name', { required: true })}
              />
              <p className="error-text">{errors.name?.message}</p>
            </div>

            <div className="form__group">
              <label htmlFor="membership" className="form__label">
                Membership
              </label>
              {renderRadios()}
            </div>

            <div className="form__group">
              <label htmlFor="description" className="form__label">
                Description
              </label>
              <textarea
                id="description"
                className="form__textarea"
                {...register('description')}
              ></textarea>
            </div>

            <div className="form__group">
              <label htmlFor="announcement" className="form__label">
                Announcement
              </label>

              <textarea
                id="announcement"
                className="form__textarea"
                {...register('announcement')}
              ></textarea>
              <div className="text-small text-muted">
                This is visible only to members of the group.
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <button
                type="button"
                className="btn btn--primary-gradient"
                onClick={() => {
                  setModal('confirmDeleteGroup');
                }}
              >
                Delete Group
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn--secondary "
                  onClick={() => {
                    navigate(`/groups/${id}`);
                  }}
                >
                  Cancel Changes
                </button>
                <button
                  type="submit"
                  className="btn btn--primary-gradient "
                  style={{
                    marginLeft: '8px',
                  }}
                >
                  {manageGroup.loading ? 'Loading' : 'Save Changes'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  };

  return (
    <main className="main">
      <Breadcrumb
        links={[
          {
            label: 'Groups',
            to: '/groups',
          },
          {
            label: groupName,
            to: `/groups/${group.data._id}`,
            loading: !groupName ? group.loading : false,
          },
        ]}
      />
      {renderSection()}
      <ConfirmDeleteGroupModal
        id={id || ''}
        active={modal === 'confirmDeleteGroup'}
        onClose={() => {
          setModal('');
        }}
      />
    </main>
  );
});
