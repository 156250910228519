/**
 *
 * Asynchronously loads the component for ForgRegisterOtpPageotOtpPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RegisterOtpPage = lazyLoad(
  () => import('./index'),
  module => module.RegisterOtpPage,
);
