import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { groupSaga } from './saga';
import { GroupState } from './types';

export const initialState: GroupState = {
  groups: {
    loading: false,
    data: [],
    error: null,
  },
  showMore: {
    loading: false,
    error: null,
  },
  pagination: {
    page: 0,
    limit: 10,
    totalPages: 0,
  },
  memberships: {
    loading: false,
    data: [],
    error: null,
  },
};

const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    groups(state, action: PayloadAction<any>) {
      state.groups.loading = true;
      state.groups.error = null;
      state.groups.data = [];
    },
    groupsSuccess(state, action: PayloadAction<any>) {
      state.groups.data = action.payload.groups;
      state.pagination = action.payload.pagination;
      state.groups.loading = false;
    },
    groupsError(state, action: PayloadAction<any>) {
      state.groups.loading = false;
      state.groups.error = action.payload;
      state.groups.data = [];
    },
    showMore(state, action: PayloadAction<any>) {
      state.showMore.loading = true;
      state.showMore.error = false;
    },
    showMoreSuccess(state, action: PayloadAction<any>) {
      state.showMore.loading = false;
      state.groups.data = [...action.payload.groups];
      state.pagination = action.payload.pagination;
    },
    showMoreError(state, action: PayloadAction<any>) {
      state.showMore.loading = false;
      state.showMore.error = action.payload;
    },
    memberships(state) {
      state.memberships.loading = true;
      state.memberships.error = null;
      state.memberships.data = [];
    },
    membershipsSuccess(state, action: PayloadAction<any>) {
      state.memberships.data = action.payload;
      state.memberships.loading = false;
    },
    membershipsError(state, action: PayloadAction<any>) {
      state.memberships.loading = false;
      state.memberships.error = action.payload;
      state.memberships.data = [];
    },
  },
});

export const { actions: groupActions } = slice;

export const useGroupSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: groupSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = usegroupSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
