/**
 *
 * UserGroups
 *
 */
import moment from 'moment';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { myGarageActions } from '../../slice';
import { selectUserGroups } from '../../slice/selectors';
import { selectGroupData } from '../../../GroupDetailsPage/slice/selectors';
import { selectGroups } from 'app/pages/GroupsPage/slice/selectors';

interface Props {
  userId: string;
  fromGroupsPage?: boolean;
}

export const UserGroups = memo((props: Props) => {
  const { userId, fromGroupsPage } = props;
  const dispatch = useDispatch();
  const userGroups = useSelector(selectUserGroups);
  const groupData = useSelector(selectGroupData);
  const groups = useSelector(selectGroups);

  const rankValue = Math.max(...groups.data.map(val => val.rank));

  console.log('all the groups', groups);

  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '%d sec',
      ss: '%d sec',
      m: '%d min',
      mm: '%d min',
      h: '%d hour',
      hh: '%d hour',
      d: '%d day',
      dd: '%d day',
      M: '%d month',
      MM: '%d month',
      y: '%d year',
      yy: '%d year',
    },
  });

  useEffect(() => {
    dispatch(myGarageActions.userGroups(userId));
  }, []);

  const renderRole = (group: any) => {
    let role = group.members[0].role;
    if (role === 'owner') {
      return <div className="highlight">Owner</div>;
    } else if (role === 'manager') {
      return <div className="highlight">Manager</div>;
    } else if (role === 'requested') {
      return <div className="highlight">Requested</div>;
    } else if (role === 'invited') {
      return <div className="highlight">Invited</div>;
    }
    return null;
  };

  const renderNewRequests = group => {
    let role = group.members[0].role;

    if (group.requestedMembersCount > 0) {
      if (role === 'owner' || role === 'manager') {
        return (
          <div>
            <Link
              to={`/groups/${group._id}/members`}
              style={{ color: '#ff7586' }}
            >
              {group.requestedMembersCount} New Requests
            </Link>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const renderGroupList = () => {
    return userGroups.data.map(group => {
      return (
        <div className="card card--padded" key={group._id}>
          <Link to={`/groups/${group._id}`}>
            {group.name}
            {renderRole(group)}
          </Link>
          <ul className="list list--nested">
            <li className="text-medium">{renderNewRequests(group)}</li>

            <li className="text-small">{group.membersCount} members</li>
            <li className="text-small">
              Quality Rank: {group.rank} of {group.lastGroupRank}
            </li>
            <li className="text-small">
              Last Update: {moment(group.updatedAt).fromNow()}
            </li>
          </ul>
        </div>
      );
    });
  };
  const renderGroups = () => {
    if (userGroups.loading) {
      return <div>Loading...</div>;
    }
    if (userGroups.error) {
      return <div>Error</div>;
    }
    if (userGroups.data.length === 0) {
      if (fromGroupsPage) {
        return (
          <div className="card card--padded">
            <p className="text-center">You don't belong to any groups</p>
          </div>
        );
      }
      return (
        <div className="card card--padded">
          <p className="text-center">You don't belong to any groups</p>
          <div className="card__cta text-center">
            <Link to="/groups" className="btn btn--primary-gradient">
              Browse Groups
            </Link>
          </div>
        </div>
      );
    }
    return (
      <div className="card-list card-list--groups">{renderGroupList()}</div>
    );
  };
  return (
    <>
      <section>
        <h3 className="text-center">Your Groups</h3>
      </section>
      {renderGroups()}
    </>
  );
});
