import { Modal } from 'app/components/Modal';
import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectGroupData,
  selectLeaveGroup,
  alert,
} from '../../slice/selectors';
import { groupDetailsActions } from '../../slice';
import { useNavigate } from 'react-router-dom';

interface Props {
  active: boolean;
  onClose: any;
}

export const LeaveGroup = memo((props: Props) => {
  const { active, onClose } = props;
  const groupData = useSelector(selectGroupData);
  const dispatch = useDispatch();
  const leaveGroup = useSelector(selectLeaveGroup);
  const addAlert = useSelector(alert);
  const navigate = useNavigate();

  useEffect(() => {
    if (leaveGroup.success) {
      onClose();
    }
  }, [leaveGroup]);

  useEffect(() => {
    if (addAlert.success) {
      onClose();
      navigate('/groups');
      setTimeout(() => {
        dispatch(groupDetailsActions.resetAlert());
      }, 5000);
    }
  }, [addAlert]);
  return (
    <Modal active={active} onClose={onClose}>
      <div className="modal__header">
        <h4 className="group__user">Leave Group {groupData?.name}?</h4>
      </div>
      <div
        className="modal__body"
        style={{
          boxSizing: 'border-box',
          borderRadius: '15px',
        }}
      >
        <p>Are you sure you want to leave group {groupData?.name}?</p>
      </div>

      <div className="modal__actions ">
        <button
          type="button"
          className="btn btn--secondary-gradient btn--small"
          onClick={() => {
            onClose();
          }}
        >
          No, Don't Leave
        </button>
        <button
          type="button"
          className="btn btn--primary-gradient btn--small"
          data-acknowledge="You have left the group GroupName."
          data-action-target="btnGroupLeaveGroup"
          onClick={() => {
            dispatch(groupDetailsActions.leaveGroup(groupData._id));
            dispatch(groupDetailsActions.alert());
          }}
        >
          {leaveGroup.loading ? 'Loading...' : 'Yes, Leave Group'}
        </button>
      </div>
    </Modal>
  );
});
