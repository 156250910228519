import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/header';
import { request } from 'utils/request';
import { manageGroupActions as actions } from '.';

function* manageGroup(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/groups/${action.payload.id}`;
    let response = yield call(request, requestURL, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify(action.payload.data),
    });

    yield put(actions.manageGroupSuccess(response));
  } catch (error) {
    yield put(actions.manageGroupError(error));
  }
}

function* deleteGroup(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/groups`;
    let response = yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        groupId: action.payload,
      }),
    });

    yield put(actions.deleteGroupSuccess(response));
  } catch (err) {
    console.group(err);
    yield put(actions.deleteGroupError(true));
  }
}

export function* manageGroupSaga() {
  yield takeLatest(actions.manageGroup.type, manageGroup);
  yield takeLatest(actions.deleteGroup.type, deleteGroup);
}
