/**
 *
 * GroupsChat
 *
 */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { groupsChatActions } from './slice';
import io, { Socket } from 'socket.io-client';
import {
  selectCreateMessage,
  selectMessages,
  selectMessagesPagination,
  selectMessagesShowMore,
} from './slice/selectors';
import moment from 'moment';
import { selectIsAuthenticated } from '../HomePage/slice/selectors';
import { Button } from 'app/components/Button';
import { Link, Navigate } from 'react-router-dom';

import FoundingMemberBadge from 'assets/svg/badges/Founding_Member.svg';
import PremierMemberBadge from 'assets/svg/badges/Premier_Member.svg';
import PowerMemberBadge from 'assets/svg/badges/Power_Member.svg';
import CollectorBadge from 'assets/svg/badges/Collector.svg';
import SuperCollectorBadge from 'assets/svg/badges/Super_Collector.svg';

import ReactTooltip from 'react-tooltip';

const getImage = (name: string) => {
  if (name === 'Founding Member') {
    return FoundingMemberBadge;
  }
  if (name === 'Premier Member') {
    return PremierMemberBadge;
  }
  if (name === 'Power Member') {
    return PowerMemberBadge;
  }
  if (name === 'Collector Member') {
    return CollectorBadge;
  }
  if (name === 'Super Collector') {
    return SuperCollectorBadge;
  }
};

interface Props {
  tabCondition: boolean;
}

export const GroupsChat = memo((props: Props) => {
  const { tabCondition } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const messages = useSelector(selectMessages);
  const createMessage = useSelector(selectCreateMessage);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const pagination = useSelector(selectMessagesPagination);
  const showMore = useSelector(selectMessagesShowMore);

  const [message, setMessage] = useState('');

  const [socket, setSocket] = useState<Socket<any, any> | null>(null);

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_API_URI}`);
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  useEffect(() => {
    if (socket) {
      const messageListener = message => {
        dispatch(groupsChatActions.setMessageAtBeggining(message));
      };

      socket.on('message', messageListener);
      socket.emit('join', id);

      return () => {
        socket.off('message', messageListener);
      };
    }
    return () => {};
  }, [socket]);

  useEffect(() => {
    dispatch(
      groupsChatActions.messages({
        groupId: id,
        limit: pagination.limit,
      }),
    );
  }, []);

  useEffect(() => {
    if (createMessage.success) {
      dispatch(groupsChatActions.resetCreateMessage());
      setMessage('');
    }
  }, [createMessage]);

  const renderImage = (badge: any) => {
    if (badge) {
      let image = getImage(badge.name);
      if (image) {
        return (
          <div
            className="badge badge--thumbnail"
            data-tip
            data-for={badge?._id}
          >
            <img
              className="badge__icon"
              src={image}
              alt={badge.name}
              style={{
                padding: '0.1rem',
                objectFit: 'contain',
                width: '100%',
              }}
            />
            <ReactTooltip place="top" effect="solid" id={badge?._id}>
              <span>{badge.name}</span>
            </ReactTooltip>
          </div>
        );
      }
      return <div className="badge no-badge--thumbnail"></div>;
    }
    return <div className="badge no-badge--thumbnail"></div>;
  };

  const renderBadges = badges => {
    let tempRandomValue = Math.floor(Math.random() * 10000);

    return (
      <div
        className="car__badges"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {renderImage(badges[0])}
        {badges.length > 1 && (
          <div
            className="rank rank--increase"
            data-tip
            data-for={`show-${tempRandomValue}`}
          >
            +{badges.length - 1}
            <ReactTooltip
              place="top"
              effect="solid"
              id={`show-${tempRandomValue}`}
            >
              {badges.slice(1).map(badge => (
                <>
                  <span>{badge.name}</span>
                  <br />
                </>
              ))}
            </ReactTooltip>
          </div>
        )}
      </div>
    );
  };

  const renderMessages = () => {
    if (messages.loading) {
      return <div>Loading...</div>;
    }
    if (messages.error) {
      return <div>Error: {messages.error}</div>;
    }
    if (messages.data.length === 0) {
      return <div>No messages</div>;
    }
    return messages.data.map(message => {
      if (message.type === 'joined') {
        return (
          <div className="card" key={message._id}>
            <div className="card__header card__header--chat">
              <div className="card__user">
                {renderBadges(message.sender.badges)}
                {message.sender.username}
              </div>
              <div className="card__elapsed-time">Joined the Group</div>
            </div>
            <div className="card__content"></div>
          </div>
        );
      }
      if (message.type === 'left') {
        return (
          <div className="card" key={message._id}>
            <div className="card__header card__header--chat">
              <div className="card__user">
                {renderImage(message.sender.badges[0])}
                {message.rankIncreased ? (
                  <div className="rank rank--increase">+1</div>
                ) : null}
                {message.sender.username}
              </div>
              <div className="card__elapsed-time">Left the Group</div>
            </div>
            <div className="card__content"></div>
          </div>
        );
      }
      return (
        <div className="card" key={message._id}>
          <div className="card__header card__header--chat">
            <div className="card__user">
              {/*{renderImage(message.sender.badges[0])}*/}
              {renderBadges(message.sender.badges)}
              {message.rankIncreased ? (
                <div className="rank rank--increase">+1</div>
              ) : null}

              <Link to={`/garage/${message.sender._id}`}>
                {message.sender.username}
              </Link>
            </div>
            <div className="card__elapsed-time">
              {moment(message.createdAt).fromNow()}
            </div>
          </div>
          <div className="card__content">
            <p>{message.message}</p>
          </div>
        </div>
      );
    });
  };

  if (!isAuthenticated || !tabCondition) {
    return <Navigate to={`/groups/${id}`} />;
  }
  const renderShowMore = () => {
    if (pagination.next) {
      return (
        <div
          className="card"
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            role="presentation"
            onClick={() => {
              dispatch(
                groupsChatActions.showMore({
                  groupId: id,
                  limit: pagination.limit,
                  next: pagination.next,
                }),
              );
            }}
          >
            {showMore.loading ? 'Loading' : 'Show More'}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="tab-content__pane" id="chat">
      <div className="card card--form card--chat">
        <form className="form">
          <div className="form__group">
            <label htmlFor="yourMessage" className="form__label">
              Your Message / Reply
            </label>
            <textarea
              id="yourMessage"
              className="form__textarea form__textarea--short"
              value={message}
              onChange={e => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="form__group form__group--cta">
            <Button
              type="button"
              className="btn btn--primary btn--primary-gradient btn--small"
              onClick={() => {
                dispatch(
                  groupsChatActions.createMessage({
                    groupId: id,
                    message,
                  }),
                );
              }}
              disabled={!message}
            >
              {createMessage.loading ? 'Loading' : 'Post Message'}
            </Button>
          </div>
        </form>
      </div>

      <div className="cards-stacked cards-stacked--chat" id="chat">
        {renderMessages()}
        {renderShowMore()}
      </div>
    </div>
  );
});
