/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './styles/css/style.css';
import { GlobalStyle } from 'styles/global-styles';
import { useTranslation } from 'react-i18next';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { MyGaragePage } from './pages/MyGaragePage/Loadable';
import { BrowseRidesPage } from './pages/BrowseRidesPage/Loadable';
import { ShopRidePage } from './pages/ShopRidePage';
import { RankRidePage } from './pages/RankRidePage/Loadable';
import { RankVinPage } from './pages/RankVinPage/Loadable';
import { RankResultPage } from './pages/RankResultPage/Loadable';

import { LoginPage } from './pages/LoginPage/Loadable';
import { SignUpPage } from './pages/SignUpPage/Loadable';
import { SignUpVerifyPage } from './pages/SignUpVerifyPage/Loadable';
import { RegisterOtpPage } from './pages/RegisterOtpPage/Loadable';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage/Loadable';
import { ChangePasswordPage } from './pages/ChangePasswordPage/Loadable';

import { GroupsPage } from './pages/GroupsPage';
import { ManageGroupPage } from './pages/ManageGroupPage';
import { GroupDetailsPage } from './pages/GroupDetailsPage';
import { CreateGroupPage } from './pages/CreateGroupPage';

import { PublicRoute } from './components/PublicRoute';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { selectUserLoading } from './pages/HomePage/slice/selectors';

import { RideUpdatePage } from './pages/RideUpdatePage/Loadable';
import { LogoutPage } from './pages/LogoutPage/Loadable';
import { RidePage } from './pages/RidePage/Loadable';

import { MyHomePage } from './pages/MyHomePage/Loadable';
import { MyAccountPage } from './pages/MyAccountPage/Loadable';
import { ProfilePage } from './pages/ProfilePage/Loadable';
import { ChangeEmail } from './pages/ChangeEmail/Loadable';
import { ChangeEmailVerify } from './pages/ChangeEmailVerify/Loadable';
import { EmailChangedPage } from './pages/EmailChangedPage/Loadable';
import { ChangePhone } from './pages/ChangePhone/Loadable';
import { ChangePhoneVerify } from './pages/ChangePhoneVerify/Loadable';
import { PhoneChangedPage } from './pages/PhoneChangedPage/Loadable';

import { NotificationSettingsPage } from './pages/NotificationSettingsPage/Loadable';
import { SecurityPage } from './pages/SecurityPage/Loadable';
import { AccountChangePasswordPage } from './pages/AccountChangePasswordPage/Loadable';
import { PasswordChangedPage } from './pages/PasswordChangedPage/Loadable';

import { homePageActions, useHomePageSlice } from './pages/HomePage/slice';
import { useChangeEmailSlice } from './pages/ChangeEmail/slice';
import { useSignUpVerifySlice } from './pages/SignUpVerifyPage/slice';
import { useForgotPasswordSlice } from './pages/ForgotPasswordPage/slice';
import { groupActions, useGroupSlice } from './pages/GroupsPage/slice';
import { useGroupDetailsSlice } from './pages/GroupDetailsPage/slice';
import { useChangePhoneSlice } from './pages/ChangePhone/slice';
import { ForgotOtpPage } from './pages/ForgotOtpPage/Loadable';
import { GaragePage } from './pages/GaragePage/Loadable';
import { AboutUs } from './pages/AboutUs/Loadable';
import { Faq } from './pages/Faq/Loadable';
import { Privacy } from './pages/Privacy/Loadable';
import { Terms } from './pages/Terms/Loadable';
import { ContactUs } from './pages/ContactUs/Loadable';

export function App() {
  const { i18n } = useTranslation();
  useHomePageSlice();
  useGroupDetailsSlice();
  useGroupSlice();
  useSignUpVerifySlice();
  useForgotPasswordSlice();
  useChangeEmailSlice();
  useChangePhoneSlice();
  const userLoading = useSelector(selectUserLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(homePageActions.getUser());
    dispatch(groupActions.memberships());
  }, []);

  if (userLoading) {
    return <div>Loading</div>;
  }
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - React Boilerplate"
        defaultTitle="React Boilerplate"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>

      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/my-garage" element={<MyGaragePage />} />
        <Route path="/garage/:id" element={<GaragePage />} />
        <Route path="/my-home" element={<MyHomePage />} />
        <Route path="/my-account" element={<MyAccountPage />} />
        <Route path="/my-account/profile" element={<ProfilePage />} />
        <Route
          path="/my-account/profile/password-changed"
          element={<PasswordChangedPage />}
        />
        <Route
          path="/my-account/notifications"
          element={<NotificationSettingsPage />}
        />
        <Route path="/my-account/security" element={<SecurityPage />} />
        <Route
          path="/my-account/security/change-password"
          element={<AccountChangePasswordPage />}
        />
        <Route
          path="/my-account/profile/change-email"
          element={<ChangeEmail />}
        />
        <Route
          path="/my-account/profile/change-email/verify"
          element={<ChangeEmailVerify />}
        />
        <Route
          path="/my-account/profile/email-changed"
          element={<EmailChangedPage />}
        />
        <Route
          path="/my-account/profile/change-phone"
          element={<ChangePhone />}
        />
        <Route
          path="/my-account/profile/change-phone/verify"
          element={<ChangePhoneVerify />}
        />
        <Route
          path="/my-account/profile/phone-changed"
          element={<PhoneChangedPage />}
        />
        <Route path="/browse-rides" element={<BrowseRidesPage />} />
        <Route path="/shop-ride" element={<ShopRidePage />} />
        <Route path="/rank-ride" element={<RankRidePage />} />
        <Route path="/rank-vin" element={<RankVinPage />} />
        <Route path="/rank-result/:id" element={<RankResultPage />} />
        <Route path="/ride/:id" element={<RidePage />} />
        <Route path="/ride/:id/edit" element={<RideUpdatePage />} />
        <Route path="/groups" element={<GroupsPage />} />
        <Route path="/groups/create" element={<CreateGroupPage />} />
        <Route path="/groups/:id/*" element={<GroupDetailsPage />} />
        <Route path="/groups/:id/manage" element={<ManageGroupPage />} />
        <Route path="/about-us/" element={<AboutUs />} />
        <Route path="/faq/" element={<Faq />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/login" element={<PublicRoute component={LoginPage} />} />
        <Route
          path="/logout"
          element={<PublicRoute component={LogoutPage} />}
        />
        <Route path="/login/forgot-password/otp" element={<ForgotOtpPage />} />
        <Route
          path="/login/change-password"
          element={<PublicRoute component={ChangePasswordPage} />}
        />
        <Route
          path="/login/forgot-password"
          element={<PublicRoute component={ForgotPasswordPage} />}
        />
        <Route
          path="/register"
          element={<PublicRoute component={SignUpPage} />}
        />
        <Route path="/register/verify" element={<SignUpVerifyPage />} />
        <Route path="/register/verify/otp" element={<RegisterOtpPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <GlobalStyle />
    </BrowserRouter>
  );
}
