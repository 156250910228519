import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.groupRides || initialState;

export const selectRides = createSelector([selectSlice], state => state.rides);
export const selectPagination = createSelector(
  [selectSlice],
  state => state.pagination,
);
