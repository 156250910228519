import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { forgotPasswordSaga } from './saga';
import { ForgotPasswordState } from './types';

export const initialState: ForgotPasswordState = {
  forgotPassword: {
    loading: false,
    success: null,
    error: null,
  },
};

const slice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    forgotPassword(state, action: PayloadAction<any>) {
      state.forgotPassword.loading = true;
      state.forgotPassword.error = null;
      state.forgotPassword.success = false;
    },
    forgotPasswordSuccess(state, action: PayloadAction<any>) {
      state.forgotPassword.loading = false;
      state.forgotPassword.success = action.payload;
    },
    forgotPasswordError(state, action: PayloadAction<any>) {
      state.forgotPassword.loading = false;
      state.forgotPassword.error = action.payload;
      state.forgotPassword.success = false;
    },
    resetForgotPassword(state) {
      state.forgotPassword.loading = false;
      state.forgotPassword.error = null;
      state.forgotPassword.success = null;
    },
  },
});

export const { actions: forgotPasswordActions } = slice;

export const useForgotPasswordSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: forgotPasswordSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useForgotPasswordSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
