import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { groupRidesSaga } from './saga';
import { GroupRidesState } from './types';

export const initialState: GroupRidesState = {
  rides: {
    loading: true,
    data: [],
    error: null,
  },
  pagination: {
    page: 0,
    limit: 10,
    totalPages: 0,
  },
};

const slice = createSlice({
  name: 'groupRides',
  initialState,
  reducers: {
    rides(state, action: PayloadAction<any>) {
      state.rides.loading = true;
      state.rides.error = null;
      state.rides.data = [];
    },
    ridesSuccess(state, action: PayloadAction<any>) {
      state.rides.data = action.payload.rides;
      state.pagination = action.payload.pagination;
      state.rides.loading = false;
    },
    ridesError(state, action: PayloadAction<any>) {
      state.rides.loading = false;
      state.rides.error = action.payload;
      state.rides.data = [];
    },
  },
});

export const { actions: groupRidesActions } = slice;

export const useGroupRidesSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: groupRidesSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGroupRidesSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
