import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { signUpVerifySaga } from './saga';
import { SignUpVerifyState } from './types';

export const initialState: SignUpVerifyState = {
  verifyViaEmail: {
    loading: false,
    success: null,
    error: null,
  },
  verifyViaSms: {
    loading: false,
    success: null,
    error: null,
  },
};

const slice = createSlice({
  name: 'signUpVerify',
  initialState,
  reducers: {
    verifyViaEmail(state) {
      state.verifyViaEmail.loading = true;
      state.verifyViaEmail.error = null;
      state.verifyViaEmail.success = false;
    },
    verifyViaEmailSuccess(state, action: PayloadAction<any>) {
      state.verifyViaEmail.loading = false;
      state.verifyViaEmail.success = action.payload;
    },
    verifyViaEmailError(state, action: PayloadAction<any>) {
      state.verifyViaEmail.loading = false;
      state.verifyViaEmail.error = action.payload;
      state.verifyViaEmail.success = false;
    },
    resetVerifyViaEmail(state) {
      state.verifyViaEmail.success = null;
    },
    verifyViaSms(state) {
      state.verifyViaSms.loading = true;
      state.verifyViaSms.error = null;
      state.verifyViaSms.success = false;
    },
    verifyViaSmsSuccess(state, action: PayloadAction<any>) {
      state.verifyViaSms.loading = false;
      state.verifyViaSms.success = action.payload;
    },
    verifyViaSmsError(state, action: PayloadAction<any>) {
      state.verifyViaSms.loading = false;
      state.verifyViaSms.error = action.payload;
      state.verifyViaSms.success = false;
    },
    resetVerifyViaSms(state) {
      state.verifyViaSms.success = null;
    },
  },
});

export const { actions: signUpVerifyActions } = slice;

export const useSignUpVerifySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: signUpVerifySaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSignUpVerifySlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
