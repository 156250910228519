/**
 *
 * Footer
 *
 */
import React, { memo } from 'react';
import logo from 'assets/images/logo_ryr.png';
import { Link } from 'react-router-dom';

interface Props {}

export const Footer = memo((props: Props) => {
  const getYear = () => {
    return new Date().getFullYear();
  };
  return (
    <footer className="footer">
      <div className="footer__logo">
        <img src={logo} alt="Rank Your Ride Logo" />
      </div>

      <ul className="footer__social">
        <li>
          <a href="https://www.facebook.com/RankYourRide/" title="Join us on Facebook">
            <i className="fab fa-facebook-f" aria-hidden="true"></i>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/rankyourride" title="Join us on Twitter">
            <i className="fab fa-twitter" aria-hidden="true"></i>
          </a>
        </li>
        {/* Hiding Pinterest unless we need it later. <li>
          <a href="/" title="Join us on Pinterest">
            <i className="fab fa-pinterest-p" aria-hidden="true"></i>
          </a>
        </li> */}
        <li>
          <a href="https://www.instagram.com/rankyourride/" title="Join us on Instagram">
            <i className="fab fa-instagram" aria-hidden="true"></i>
          </a>
        </li>
      </ul>

      <div className="footer__copyright">
        <div>&copy; {getYear()} Rank Your Ride, All Rights Reserved</div>
        <div className="footer__contact">
          <div className="footer__phone">+1 000 000 0000</div>
          <a href="mailto:info@rankyourride.com">info@rankyourride.com</a>
        </div>
      </div>

      <div className="footer__links-legalese">
        <div className="footer__links">
          <Link to="/about-us">About Us</Link>
          <Link to="/contact-us">Contact Us</Link>

          <Link to="/faq">FAQ</Link>
        </div>
        <div className="footer__legalese">
          <Link to="/privacy">Privacy</Link>
          <Link to="/terms">Terms</Link>
        </div>
      </div>
    </footer>
  );
});
