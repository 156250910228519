/**
 *
 * Asynchronously loads the component for MyAccountPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const MyAccountPage = lazyLoad(
  () => import('./index'),
  module => module.MyAccountPage,
);
