import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.groupDetails || initialState;

export const selectGroup = createSelector([selectSlice], state => state.group);
export const selectGroupData = createSelector(
  [selectSlice],
  state => state.group.data,
);
export const selectJoinGroup = createSelector(
  [selectSlice],
  state => state.joinGroup,
);
export const selectLeaveGroup = createSelector(
  [selectSlice],
  state => state.leaveGroup,
);

export const selectRequestGroup = createSelector(
  [selectSlice],
  state => state.requestGroup,
);

export const selectAcceptInvite = createSelector(
  [selectSlice],
  state => state.acceptInvite,
);

export const selectMembers = createSelector(
  [selectSlice],
  state => state.members,
);

export const alert = createSelector([selectSlice], state => state.alert);
