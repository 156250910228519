import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { header } from 'utils/header';
import { request } from 'utils/request';
import { groupDetailsActions as actions } from '.';
import { selectGroupData } from './selectors';

function* group(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/groups/${action.payload}`;

    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(actions.groupSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(actions.groupError(true));
  }
}

function* joinGroup(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/join`;

    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        groupId: action.payload,
      }),
    });
    let groupData = yield select(selectGroupData);
    yield put(
      actions.joinGroupSuccess({
        isMember: true,
        memberCount: groupData.memberCount + 1,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.joinGroupError(true));
  }
}

function* leaveGroup(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/leave`;

    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        groupId: action.payload,
      }),
    });
    let groupData = yield select(selectGroupData);
    yield put(
      actions.leaveGroupSuccess({
        isMember: false,
        memberCount: groupData.memberCount - 1,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.leaveGroupError(true));
  }
}

function* requestGroup(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/request`;

    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        groupId: action.payload,
      }),
    });
    yield put(
      actions.requestGroupSuccess({
        isRequested: true,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.requestGroupError(true));
  }
}

function* acceptInvite(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/accept-invite`;

    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        groupId: action.payload,
      }),
    });
    let groupData = yield select(selectGroupData);
    yield put(
      actions.acceptInviteSuccess({
        isMember: true,
        isInvited: false,
        memberCount: groupData.memberCount + 1,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.acceptInviteError(true));
  }
}

function* getMembers(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/search?groupId=${action.payload.id}&query=${action.payload.search}`;
    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    const data: any[] = [];

    if (response.length > 0) {
      response.forEach(member => {
        let obj = { label: '', value: '', role: '' };
        obj.label = member.user.username;
        obj.value = member.user._id;
        obj.role = member.role;
        data.push(obj);
      });
      yield put(actions.getMembersSuccess(data));
    }
  } catch (err) {
    console.log(err);
    yield put(actions.getMembersError(true));
  }
}
function* promoteToOwner(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/promote-to-owner-and-leave`;
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        groupId: action.payload.id,
        memberId: action.payload.member,
      }),
    });
    yield put(actions.promoteToOwnerSuccess(response));
    requestURL = `/api/v1/groups/${action.payload.id}`;

    response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(actions.groupSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(actions.promoteToOwnerError(true));
  }
}

function* alert() {
  try {
    yield put(actions.addAlert('you have left the group'));
  } catch (err) {
    console.log(err);
  }
}

export function* groupDetailsSaga() {
  yield takeLatest(actions.group.type, group);
  yield takeLatest(actions.joinGroup.type, joinGroup);
  yield takeLatest(actions.leaveGroup.type, leaveGroup);
  yield takeLatest(actions.requestGroup.type, requestGroup);
  yield takeLatest(actions.acceptInvite.type, acceptInvite);
  yield takeLatest(actions.getMembers.type, getMembers);
  yield takeLatest(actions.promoteToOwner.type, promoteToOwner);
  yield takeLatest(actions.alert.type, alert);
}
