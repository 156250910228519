import { selectUser } from 'app/pages/HomePage/slice/selectors';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import { request } from 'utils/request';
import { signUpVerifyActions as actions } from '.';

function* verifyViaEmail() {
  try {
    let requestURL = `/api/v1/auth/sign-up/otp`;
    let user = yield select(selectUser);
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: user.data.email,
      }),
    });

    yield put(actions.verifyViaEmailSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(actions.verifyViaEmailError(true));
  }
}

function* verifyViaSms() {
  try {
    let requestURL = `/api/v1/auth/sign-up/otp-phone`;
    let user = yield select(selectUser);
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: user.data.phone,
      }),
    });

    yield put(actions.verifyViaSmsSuccess(response));
  } catch (err) {
    console.log(err);
    yield put(actions.verifyViaSmsError(true));
  }
}

export function* signUpVerifySaga() {
  yield takeLatest(actions.verifyViaEmail.type, verifyViaEmail);
  yield takeLatest(actions.verifyViaSms.type, verifyViaSms);
}
