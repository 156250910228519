import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.homePage || initialState;

export const selectHomePage = createSelector([selectSlice], state => state);
export const selectUser = createSelector([selectHomePage], state => state.user);

export const selectUserData = createSelector(
  [selectHomePage],
  state => state.user.data,
);

export const selectUserLoading = createSelector(
  [selectHomePage],
  state => state.user.loading,
);

export const selectIsAuthenticated = createSelector(
  [selectHomePage],
  state => state.isAuthenticated,
);

export const selectSearchZip = createSelector(
  [selectSlice],
  state => state.searchZip,
);
