/**
 *
 * CreateGroupPage
 *
 */
import React, { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { createGroupActions, useCreateGroupSlice } from './slice';
import { selectCreateGroup } from './slice/selectors';
import { Breadcrumb } from 'app/components/Breadcrumb';
import { selectMemberships } from '../GroupsPage/slice/selectors';

interface Props {}

const schema = yup.object({
  name: yup
    .string()
    .required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(128, 'Name must be less than 128 characters'),
  description: yup.string().required('Description is required'),
});

export const CreateGroupPage = memo((props: Props) => {
  useCreateGroupSlice();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      membership: 'open',
      description: '',
      announcement: '',
    },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createGroup = useSelector(selectCreateGroup);
  const memberships = useSelector(selectMemberships);

  useEffect(() => {
    if (createGroup.success) {
      dispatch(createGroupActions.resetCreateGroup());
      navigate(`/groups/${createGroup.success.group._id}`);
    }
  }, [createGroup]);

  const onSubmit = (data: any) => {
    dispatch(createGroupActions.createGroup(data));
  };

  const renderRadios = () => {
    if (memberships.loading) {
      return <>Loading</>;
    }
    if (memberships.error) {
      return <>Error</>;
    }
    return memberships.data.map(option => (
      <div className="form-control form-control--radio" key={option.name}>
        <input
          {...register('membership')}
          type="radio"
          className="custom-radio"
          id={option.name}
          value={option.name}
          name="membership"
        />
        <label htmlFor={option.name}>
          {option.title} -
          <span className="text-muted text-small">{option.description}</span>
        </label>
      </div>
    ));
  };

  return (
    <main className="main">
      <Breadcrumb
        links={[
          {
            label: 'Groups',
            to: '/groups',
          },
        ]}
      />
      <section>
        <h2 className="text-center">Create Group</h2>
        <div className="card card--form">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form__group">
              <label htmlFor="groupName" className="form__label">
                Name
              </label>
              <input
                type="groupName"
                id="groupName"
                className="form__input"
                {...register('name', { required: true })}
              />
              <p className="error-text">{errors.name?.message}</p>
            </div>

            <div className="form__group">
              <label htmlFor="membership" className="form__label">
                Membership
              </label>
              {renderRadios()}
            </div>

            <div className="form__group">
              <label htmlFor="description" className="form__label">
                Description
              </label>
              <textarea
                id="description"
                className="form__textarea"
                {...register('description')}
              ></textarea>
              <p className="error-text">{errors.description?.message}</p>
            </div>

            <div className="form__group">
              <label htmlFor="announcement" className="form__label">
                Announcement
              </label>

              <textarea
                id="announcement"
                className="form__textarea"
                {...register('announcement')}
              ></textarea>
              <div className="text-small text-muted">
                This is visible only to members of the group.
              </div>
            </div>

            <div className="form__group form__group--cta">
              <button
                type="submit"
                className="btn btn--primary-gradient btn--small"
              >
                {createGroup.loading ? 'Loading' : 'Create Group'}
              </button>
              <button
                type="button"
                className="btn btn--secondary btn--small"
                onClick={() => {
                  navigate('/groups');
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
});
