import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import { request } from 'utils/request';
import { forgotPasswordActions as actions } from '.';

function* forgotPassword(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/auth/login/forgot-password`;
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: action.payload,
      }),
    });
    yield put(
      actions.forgotPasswordSuccess({
        email: action.payload,
        response: response,
      }),
    );
    localStorage.setItem('forget-password-user-id', response.user_id);
  } catch (err: any) {
    console.log(err);
    if (err.response) {
      let error = yield err.response.json();
      if (error.message) {
        yield put(actions.forgotPasswordError(error.message));
      }
    } else {
      yield put(actions.forgotPasswordError('Something went wrong'));
    }
  }
}

export function* forgotPasswordSaga() {
  yield takeLatest(actions.forgotPassword.type, forgotPassword);
}
