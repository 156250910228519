import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/header';
import { request } from 'utils/request';
import { changePhoneActions as actions } from '.';

function* sendOtpToChangePhone(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/account/profile/change-phone`;
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        newPhone: action.payload,
      }),
    });
    yield put(
      actions.sendOtpToChangePhoneSuccess({
        response,
        phone: action.payload,
      }),
    );
  } catch (err: any) {
    console.log(err);
    if (err.response) {
      let error = yield err.response.json();
      if (error.message) {
        yield put(actions.sendOtpToChangePhoneError(error.message));
      }
    } else {
      yield put(actions.sendOtpToChangePhoneError('Something went wrong'));
    }
  }
}

export function* ChangePhoneSaga() {
  yield takeLatest(actions.sendOtpToChangePhone.type, sendOtpToChangePhone);
}
