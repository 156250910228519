/**
 *
 * Asynchronously loads the component for ChangePhoneVerify
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ChangePhoneVerify = lazyLoad(
  () => import('./index'),
  module => module.ChangePhoneVerify,
);
