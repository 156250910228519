/**
 *
 * Asynchronously loads the component for MyHomePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const MyHomePage = lazyLoad(
  () => import('./index'),
  module => module.MyHomePage,
);
