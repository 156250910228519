import { PayloadAction } from '@reduxjs/toolkit';
import { groupDetailsActions } from 'app/pages/GroupDetailsPage/slice';
import { selectGroupData } from 'app/pages/GroupDetailsPage/slice/selectors';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/header';
import { request } from 'utils/request';
import { groupMembersActions as actions } from '.';
import { selectMembers } from './selectors';

function* members(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members?page=${action.payload.page}&limit=${action.payload.limit}&groupId=${action.payload.groupId}`;

    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(
      actions.membersSuccess({
        members: response.members || [],
        pagination: response.pagination,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.membersError(true));
  }
}

function* showMore(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members?page=${action.payload.page}&limit=${action.payload.limit}&groupId=${action.payload.groupId}`;
    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(
      actions.showMoreSuccess({
        members: response.members,
        pagination: response.pagination,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.showMoreError(true));
  }
}

function* removeMember(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/remove`;
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify(action.payload),
    });

    let members = yield select(selectMembers);
    let tempMembers = JSON.parse(JSON.stringify(members.data));
    let index = tempMembers.findIndex(
      member => member._id === action.payload.memberId,
    );
    tempMembers.splice(index, 1);
    yield put(actions.setMembers(tempMembers));
    let groupData = yield select(selectGroupData);
    yield put(
      groupDetailsActions.setGroup({
        memberCount: groupData.memberCount - 1,
      }),
    );
    yield put(actions.removeMemberSuccess(true));
  } catch (err) {
    console.log(err);
    yield put(actions.removeMemberError(true));
  }
}

function* promoteToMember(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/promote-to-member`;
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify(action.payload),
    });
    let members = yield select(selectMembers);
    let tempMembers = JSON.parse(JSON.stringify(members.data));
    let index = tempMembers.findIndex(
      member => member._id === action.payload.memberId,
    );
    tempMembers[index].role = 'member';
    yield put(actions.setMembers(tempMembers));
    yield put(actions.promoteToMemberSuccess(true));
    let groupData = yield select(selectGroupData);
    yield put(
      groupDetailsActions.setGroup({
        memberCount: groupData.memberCount + 1,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.promoteToMemberError(true));
  }
}

function* promoteToManager(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/promote-to-manager`;
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify(action.payload),
    });
    let members = yield select(selectMembers);
    let tempMembers = JSON.parse(JSON.stringify(members.data));
    let index = tempMembers.findIndex(
      member => member._id === action.payload.memberId,
    );
    tempMembers[index].role = 'manager';
    yield put(actions.setMembers(tempMembers));
    yield put(actions.promoteToManagerSuccess(true));
  } catch (err) {
    console.log(err);
    yield put(actions.promoteToManagerError(true));
  }
}

function* demoteToMember(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/demote-to-member`;
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify(action.payload),
    });
    let members = yield select(selectMembers);
    let tempMembers = JSON.parse(JSON.stringify(members.data));
    let index = tempMembers.findIndex(
      member => member._id === action.payload.memberId,
    );
    tempMembers[index].role = 'member';
    yield put(actions.setMembers(tempMembers));
    yield put(actions.demoteToMemberSuccess(true));
  } catch (err) {
    console.log(err);
    yield put(actions.demoteToMemberError(true));
  }
}

function* ignoreRequest(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/ignore-request`;
    yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify(action.payload),
    });
    let members = yield select(selectMembers);
    let tempMembers = JSON.parse(JSON.stringify(members.data));
    let index = tempMembers.findIndex(
      member => member._id === action.payload.memberId,
    );
    tempMembers.splice(index, 1);
    yield put(actions.setMembers(tempMembers));
    let groupData = yield select(selectGroupData);
    yield put(
      groupDetailsActions.setGroup({
        memberCount: groupData.memberCount - 1,
      }),
    );
    yield put(actions.ignoreRequestSuccess(true));
  } catch (err) {
    console.log(err);
    yield put(actions.ignoreRequestError(true));
  }
}

function* invite(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/members/invite`;
    let member = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        groupName: action.payload.groupName,
        groupId: action.payload.groupId,
        username: action.payload.username,
      }),
    });

    let members = yield select(selectMembers);
    let tempMembers = JSON.parse(JSON.stringify(members.data));
    yield put(actions.setMembers([member, ...tempMembers]));
    yield put(actions.inviteSuccess(true));
  } catch (err: any) {
    console.log(err);
    if (err.response) {
      let error = yield err.response.json();
      if (error.message) {
        yield put(actions.inviteError(error.message));
      }
    } else {
      yield put(actions.inviteError('Something went wrong'));
    }
  }
}

export function* groupMembersSaga() {
  yield takeLatest(actions.members.type, members);
  yield takeLatest(actions.showMore.type, showMore);
  yield takeLatest(actions.removeMember.type, removeMember);
  yield takeLatest(actions.promoteToMember.type, promoteToMember);
  yield takeLatest(actions.promoteToManager.type, promoteToManager);
  yield takeLatest(actions.demoteToMember.type, demoteToMember);
  yield takeLatest(actions.ignoreRequest.type, ignoreRequest);
  yield takeLatest(actions.invite.type, invite);
}
