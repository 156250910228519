/**
 *
 * MemberModal
 *
 */
import { Modal } from 'app/components/Modal';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupMembersActions } from '../../slice';
import {
  selectPromoteToManager,
  selectRemoveMember,
} from '../../slice/selectors';

interface Props {
  active: boolean;
  onClose: any;
  modalData: any;
}

export const MemberModal = memo((props: Props) => {
  const { active, onClose, modalData } = props;
  const dispatch = useDispatch();
  const removeMember = useSelector(selectRemoveMember);
  const promoteToManager = useSelector(selectPromoteToManager);

  useEffect(() => {
    if (removeMember.success) {
      dispatch(groupMembersActions.resetRemoveMember());
      onClose();
    }
  }, [removeMember]);

  useEffect(() => {
    if (promoteToManager.success) {
      dispatch(groupMembersActions.resetPromoteToManager());
      onClose();
    }
  }, [promoteToManager]);

  return (
    <Modal active={active} onClose={onClose}>
      <div className="modal__header">
        <h4 className="group__user">
          {modalData && modalData.member.user.username}
        </h4>
        <div className="group__user-status">Member</div>
      </div>
      <div className="modal__actions">
        <button
          type="button"
          className="btn btn--primary-gradient btn--small"
          onClick={() => {
            dispatch(
              groupMembersActions.promoteToManager({
                groupId: modalData.group._id,
                memberId: modalData.member._id,
              }),
            );
          }}
        >
          {promoteToManager.loading ? 'Loading' : 'Promote to Manager'}
        </button>
        <button
          type="button"
          className="btn btn--secondary-gradient btn--small"
          onClick={() => {
            dispatch(
              groupMembersActions.removeMember({
                groupId: modalData.group._id,
                memberId: modalData.member._id,
              }),
            );
          }}
        >
          {removeMember.loading ? 'Loading' : 'Remove from Group'}
        </button>
      </div>
    </Modal>
  );
});
