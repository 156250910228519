/**
 *
 * Asynchronously loads the component for ChangePasswordPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ChangePasswordPage = lazyLoad(
  () => import('./index'),
  module => module.ChangePasswordPage,
);
