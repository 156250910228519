/**
 *
 * Asynchronously loads the component for BrowseRidesPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const BrowseRidesPage = lazyLoad(
  () => import('./index'),
  module => module.BrowseRidesPage,
);
