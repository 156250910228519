/**
 *
 * Asynchronously loads the component for EmailChangedPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const EmailChangedPage = lazyLoad(
  () => import('./index'),
  module => module.EmailChangedPage,
);
