/**
 *
 * Asynchronously loads the component for RankResultPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const RankResultPage = lazyLoad(
  () => import('./index'),
  module => module.RankResultPage,
);
