import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { header } from 'utils/header';
import { request } from 'utils/request';
import { groupsChatActions as actions } from '.';

function* messages(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/chat?groupId=${action.payload.groupId}&limit=${action.payload.limit}`;
    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(
      actions.messagesSuccess({
        messages: response.messages,
        pagination: response.pagination,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.messagesError(true));
  }
}
function* showMore(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/chat?groupId=${action.payload.groupId}&limit=${action.payload.limit}&next=${action.payload.next}`;
    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
    });
    yield put(
      actions.showMoreSuccess({
        messages: response.messages,
        pagination: response.pagination,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.showMoreError(true));
  }
}

function* createMessage(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/chat`;
    let response = yield call(request, requestURL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${header()}`,
      },
      body: JSON.stringify({
        groupId: action.payload.groupId,
        message: action.payload.message,
        type: 'message',
      }),
    });
    yield put(actions.createMessageSuccess(response));
  } catch (error) {
    yield put(actions.createMessageError(error));
  }
}
export function* groupsChatSaga() {
  yield takeLatest(actions.messages.type, messages);
  yield takeLatest(actions.showMore.type, showMore);
  yield takeLatest(actions.createMessage.type, createMessage);
}
