/**
 *
 * SendInvite
 *
 */
import { Button } from 'app/components/Button';
import { Modal } from 'app/components/Modal';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { groupMembersActions } from '../../slice';
import { selectInvite } from '../../slice/selectors';

interface Props {
  groupId: string;
  active: boolean;
  onClose: any;
  groupName: string;
}

export const SendInvite = memo((props: Props) => {
  const { groupId, active, onClose: onCloseProp, groupName } = props;
  const [username, setUsername] = useState('');

  const dispatch = useDispatch();
  const invite = useSelector(selectInvite);

  useEffect(() => {
    if (invite.success) {
      setTimeout(() => {
        dispatch(groupMembersActions.resetInvite());
        onCloseProp();
      }, 3000);
    }
  }, [invite]);

  const onInvite = () => {
    dispatch(
      groupMembersActions.invite({
        username,
        groupId,
        groupName,
      }),
    );
  };
  const onClose = useCallback(() => {
    dispatch(groupMembersActions.resetInvite());
    setUsername('');
    onCloseProp();
  }, [dispatch, onCloseProp]);

  return (
    <Modal active={active} onClose={onClose}>
      <div className="form">
        <div className="form__group">
          <label htmlFor="username" className="form__label">
            Invite someone to Join
          </label>
          <input
            type="text"
            id="username"
            className="form__input"
            value={username}
            onChange={e => {
              setUsername(e.target.value);
              dispatch(groupMembersActions.resetInvite());
            }}
          />
          {invite.error && <div className="form__error">{invite.error}</div>}
          {invite.success && <div className="form__success">Invited</div>}
        </div>
        <div className="form__group form__group--cta">
          <Button
            type="button"
            className="btn btn--secondary btn--small"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn btn--primary btn--primary-gradient btn--small"
            onClick={onInvite}
            disabled={!username}
          >
            {invite.loading ? 'Loading' : 'Send Invitation'}
          </Button>
        </div>
      </div>
    </Modal>
  );
});
