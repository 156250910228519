import React, { memo } from 'react';
import { Modal } from 'app/components/Modal';

interface Props {
  active: any;
  onClose: any;
  message: any;
}

export const Alert = memo((props: Props) => {
  const { active, onClose, message } = props;
  return (
    <Modal active={active} onClose={onClose}>
      <div className="modal__body">
        <p>{message}</p>
      </div>
      <div className="modal__actions">
        <button
          type="button"
          className="btn btn--primary-gradient"
          onClick={() => {
            onClose();
          }}
        >
          Ok
        </button>
      </div>
    </Modal>
  );
});
