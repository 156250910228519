/**
 *
 * Asynchronously loads the component for Privacy
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Privacy = lazyLoad(
  () => import('./index'),
  module => module.Privacy,
);
