/**
 *
 * InviteModal
 *
 */
import { Modal } from 'app/components/Modal';
import React, { memo } from 'react';

interface Props {
  active: boolean;
  onClose: any;
  modalData: any;
}

export const InviteModal = memo((props: Props) => {
  const { active, onClose, modalData } = props;
  if (!modalData) {
    return null;
  }
  return (
    <Modal active={active} onClose={onClose}>
      <div className="modal__header">
        <h4 className="group__user">{modalData.member.user.username}</h4>
        <div className="group__user-status">Invited</div>
      </div>
      <div className="modal__body">
        <p>
          This user will be added to the group if they accept your invitation
          within 2 weeks.
        </p>
      </div>
      <div className="modal__actions">
        <button
          type="button"
          className="btn btn--secondary-gradient btn--small"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </button>
      </div>
    </Modal>
  );
});
