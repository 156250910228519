import { Modal } from 'app/components/Modal';
import React, { memo, useState, useEffect } from 'react';
import { ConfirmLeaveGroupModal } from '../ConfirmLeaveGroupModal';
import { useSelector, useDispatch } from 'react-redux';
import { selectMembers } from '../../slice/selectors';
import { groupDetailsActions } from '../../slice';
import { useParams } from 'react-router-dom';
import { Alert } from 'app/components/Alert';
import Select, { components } from 'react-select';
const { SingleValue, Option } = components;

interface Props {
  active: boolean;
  onClose: any;
}

export const LeaveOwnerGroupModal = memo((props: Props) => {
  const { active, onClose } = props;
  const [member, setMember] = useState('');
  const [modal, setModal] = useState('');
  const [search, setSearch] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const members = useSelector(selectMembers).data;
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(
      groupDetailsActions.getMembers({
        id: params.id,
        search: search,
      }),
    );
  }, []);

  const handleChange = event => {
    setSelectedOption(event);
    setMember(event.value);
  };

  const onConfirmClose = () => {
    setModal('');
  };

  const promoteMember = () => {
    dispatch(
      groupDetailsActions.promoteToOwner({
        id: params.id,
        member: member,
      }),
    );
    setModal('');
    props.onClose();
  };

  const onSearch = searchItem => {
    setSearch(searchItem);
    dispatch(
      groupDetailsActions.getMembers({
        id: params.id,
        search: searchItem,
      }),
    );
  };

  const IconSingleValue = props => (
    <SingleValue {...props}>
      {props.data.label}
      {props.data.role === 'manager' ? (
        <button
          className="btn btn--action"
          style={{
            float: 'right',
            marginTop: '5px',
          }}
        >
          {props.data.role}
        </button>
      ) : null}
    </SingleValue>
  );

  const IconOption = props => (
    <Option {...props}>
      {props.data.label}
      {props.data.role === 'manager' ? (
        <button
          className="btn btn--action"
          style={{
            float: 'right',
            marginTop: '5px',
          }}
        >
          {props.data.role}
        </button>
      ) : null}
    </Option>
  );
  const colourStyles = {
    singleValue: base => ({
      ...base,
      color: 'rgba(255, 255, 255, 0.5)',
    }),
    input: base => ({
      ...base,
      color: 'rgba(255, 255, 255, 0.5)',
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        background: '#13131a',
        color: 'white',
        '&:hover': { backgroundColor: 'var(--secondary-3)' },
      };
    },
    control: (base, state) => ({
      ...base,
      background: '#13131a',
      color: 'white',
      borderRadius: '10px',
      borderColor: state.isFocused ? 'var(--secondary-2)' : base.borderColor,
      '&:hover': { borderColor: 'var(--secondary-2)' },
      boxShadow: 'none',
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      marginTop: 10,
      background: '#131118',
      padding: '5px',
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      borderRadius: '10px',
      background: '#302c3f',
    }),
  };
  return (
    <Modal active={active} onClose={onClose}>
      <div
        className="modal__body"
        style={{
          boxSizing: 'border-box',
          borderRadius: '15px',
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(135, 132, 154, 0.2)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0.7rem 1.5rem',
            background: 'rgba(237, 231, 231, 0.1)',
            borderRadius: '10px',
            color: 'white',
            textAlign: 'center',
            border: `1px solid var(--neutral-700)`,
          }}
        >
          <p style={{ marginBottom: `0` }}>
            Promote someone as an Owner before you leave the group
          </p>
        </div>
        <div className="form form_group flow-spacer-medium">
          <Select
            value={selectedOption}
            onChange={handleChange}
            options={members}
            styles={colourStyles}
            components={{
              Option: IconOption,
              SingleValue: IconSingleValue,
            }}
            onInputChange={onSearch}
            isClearable={true}
          />
        </div>
      </div>
      <div className="modal__actions flow-spacer-medium">
        <button
          type="button"
          className="btn btn--primary-gradient"
          style={{ marginRight: '1.75rem', marginTop: '2.5rem' }}
          onClick={() => {
            if (member) setModal('confirmLeaveGroup');
            else setModal('alertModal');
          }}
        >
          Promote as Owner
        </button>
        <button
          type="button"
          className="btn btn--primary-gradient"
          style={{ marginTop: '2.5rem' }}
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </button>
      </div>
      <ConfirmLeaveGroupModal
        active={modal === 'confirmLeaveGroup'}
        onClose={onConfirmClose}
        onConfirm={promoteMember}
      />
      <Alert
        active={modal === 'alertModal'}
        onClose={onConfirmClose}
        message="Please select a person..!"
      />
    </Modal>
  );
});
