import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { request } from 'utils/request';
import { groupRidesActions as actions } from '.';
import { header } from '../../../../utils/header';

function* rides(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/groups/${action.payload.id}/rides?page=${action.payload.page}&limit=${action.payload.limit}`;

    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${header()}`,
        'Content-Type': 'application/json',
      },
    });
    yield put(
      actions.ridesSuccess({
        rides: response.rides,
        pagination: response.pagination,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.ridesError(true));
  }
}

export function* groupRidesSaga() {
  yield takeLatest(actions.rides.type, rides);
}
