import logo from '../../styles/images/logo_ryr.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  selectIsAuthenticated,
  selectUserData,
} from 'app/pages/HomePage/slice/selectors';
import { homePageActions } from 'app/pages/HomePage/slice';

export function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const userData = useSelector(selectUserData);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };
    if (isMenuOpen) {
      document.addEventListener('click', handleClickOutside, false);
    } else {
      document.removeEventListener('click', handleClickOutside, false);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [isMenuOpen]);

  const onLogoutButtonSubmit = (e: any) => {
    if (e.target.value === 'yes') {
      dispatch(homePageActions.logout());
      setLogoutModal(false);
      navigate('/logout');
    } else {
      setLogoutModal(false);
    }
  };
  const renderMenu = () => {
    return (
      <ul
        id="profileMenu"
        className={`menu menu--profile ${isMenuOpen ? 'show' : ''}`}
      >
        <li className="menu__item">
          <span
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              navigate('/my-account');
              setIsMenuOpen(false);
            }}
          >
            My Account
          </span>
        </li>
        <li className="menu__item">
          <span
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setLogoutModal(true);
              setIsMenuOpen(false);
            }}
          >
            Log Out
          </span>
        </li>
      </ul>
    );
  };

  const renderLogin = () => {
    if (isAuthenticated) {
      return (
        <>
          <button
            type="button"
            className="btn btn--profile"
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
          >
            <span className="profile-name">{userData.username}</span>
            <i className="fal fa-user"></i>
          </button>
          {renderMenu()}
        </>
      );
    }
    return (
      <Link to="/login" className="btn btn--secondary btn--login">
        Log in
      </Link>
    );
  };

  return (
    <header className="header">
      <button
        type="button"
        className={`btn btn--nav ${isOpen ? 'active' : ''}`}
        data-action="primaryNav"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      ></button>
      <div className="header__logo">
        <Link to="/">
          <img src={logo} alt="Rank Your Ride" />
        </Link>
      </div>
      <nav className={`nav ${isOpen ? 'open' : ''}`} id="primaryNav">
        <ul className="nav__list">
          <Link className="nav__item" to="/">
            Home
          </Link>
          <Link to="/my-garage">My Garage</Link>
          <Link to="/browse-rides" className="nav__item">
            Browse Rides
          </Link>
          <Link to="/groups" className="nav__item">
            Groups
          </Link>
          <Link to="/shop-ride" className="nav__item">
            Shop for a Ride
          </Link>
          <Link to="/rank-ride" className="nav__item">
            Rank a New Ride
          </Link>
        </ul>
      </nav>
      {renderLogin()}
      <div
        className={logoutModal === true ? 'logout-form' : 'logout-form-hidden'}
      >
        <div className="modal-dialog-logout">
          <form>
            <div
              className={
                logoutModal === true
                  ? 'logout-form-content'
                  : 'logout-form-hidden'
              }
            >
              <h3>Are you sure you want to logout?</h3>
              <div className="logout-form-btn-group">
                <button
                  type="button"
                  value="yes"
                  onClick={onLogoutButtonSubmit}
                  // className="logout-form-btn"
                  className="btn btn--primary-gradient"
                >
                  Yes
                </button>
                <button
                  type="button"
                  value="no"
                  onClick={onLogoutButtonSubmit}
                  // className="logout-form-btn"
                  className="btn btn--primary-gradient"
                >
                  No
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </header>
  );
}
