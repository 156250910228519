/**
 *
 * GroupRides
 *
 */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { selectIsAuthenticated } from '../HomePage/slice/selectors';
import { groupRidesActions } from './slice';
import { selectRides, selectPagination } from './slice/selectors';

import GoldBadge from 'assets/svg/badges/Gold_Ride.svg';
import BronzeBadge from 'assets/svg/badges/Bronze_Ride.svg';
import SilverBadge from 'assets/svg/badges/Silver_Ride.svg';
import { Link } from 'react-router-dom';

const getImage = (name: string) => {
  if (name === 'Gold') {
    return GoldBadge;
  }
  if (name === 'Bronze') {
    return BronzeBadge;
  }
  if (name === 'Silver') {
    return SilverBadge;
  }
  return null;
};

interface Props {
  tabCondition: boolean;
}

export const GroupRides = memo((props: Props) => {
  const { tabCondition } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const rides = useSelector(selectRides);
  const pagination = useSelector(selectPagination);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    dispatch(
      groupRidesActions.rides({
        id: id,
        page: pagination.page,
        limit: pagination.limit,
      }),
    );
  }, []);

  const renderImage = (badge: any) => {
    let image = getImage(badge.title);
    if (image) {
      return (
        <img
          src={image}
          alt={badge.title}
          className="badge__icon"
          style={{
            objectFit: 'contain',
            width: '100%',
          }}
        />
      );
    }
    return null;
  };

  const fetchData = page => {
    dispatch(
      groupRidesActions.rides({
        id: id,
        page: page,
        limit: pagination.limit,
      }),
    );
  };

  const renderNewShowMore = () => {
    return (
      <tr>
        <td colSpan={5}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              //  justifyContent: 'space-around',
              marginLeft: '35%',
            }}
          >
            <div
              role={'presentation'}
              style={
                pagination.page > 0
                  ? {
                      color: '#fff',
                      cursor: 'pointer',
                    }
                  : {}
              }
              onClick={() => {
                if (pagination.page > 0) {
                  fetchData(pagination.page - 1);
                }
              }}
            >
              <i className="fa fa-chevron-left"></i>
              &nbsp; Prev
            </div>
            <div className="btn" role={'presentation'}>
              <select
                style={{ color: 'grey' }}
                className="form__select"
                onChange={e => fetchData(parseFloat(e.target.value))}
                value={pagination.page}
              >
                {[...Array(pagination.totalPages).fill(10)].map((_, index) => {
                  return (
                    <option key={`Page ${index}`} value={index}>
                      Page {index + 1}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              role={'presentation'}
              style={
                pagination.page < pagination.totalPages - 1
                  ? {
                      color: '#fff',
                      cursor: 'pointer',
                    }
                  : {}
              }
              onClick={() => {
                if (pagination.page < pagination.totalPages - 1) {
                  fetchData(pagination.page + 1);
                }
              }}
            >
              Next &nbsp;
              <i className="fa fa-chevron-right"></i>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const renderRides = () => {
    if (rides.data.length === 0) {
      return (
        <tr>
          <td colSpan={3}>No rides</td>
        </tr>
      );
    }
    let rank = pagination.page * pagination.limit + 1;
    return rides?.data?.map(ride => {
      return (
        <tr key={ride?._id}>
          <td>{rank++}</td>
          <td>
            <Link to={`/garage/${ride?.user?._id}`}>
              {ride?.user?.username}
            </Link>
            <br />
            <Link to={`/ride/${ride?._id}`}>
              {ride?.year}
              <br />
              {ride?.make}-{ride?.model}
              <br />
              {ride?.trimLevel}
            </Link>
          </td>
          <td className="text-left">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {ride.badges[0] ? (
                <span className="car__status">
                  {renderImage(ride.badges[0])}
                </span>
              ) : (
                <span className="car__status"></span>
              )}
              {ride.mileage}
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderBody = () => {
    if (rides.loading) {
      return (
        <tbody>
          <tr>
            <td colSpan={3}>
              <div className="text-center">Loading...</div>
            </td>
          </tr>
        </tbody>
      );
    }
    if (rides.error) {
      return <div>Error: {rides.error}</div>;
    }

    return (
      <tbody>
        {/*{renderShowLess()}*/}
        {renderRides()}
        {renderNewShowMore()}
      </tbody>
    );
  };

  const renderShowMore = () => {
    if (rides.loading) {
      return (
        <tr>
          <td colSpan={3}>Loading</td>
        </tr>
      );
    }

    if (
      pagination.totalPages !== 1 &&
      pagination.totalPages - pagination.page > 1
    ) {
      return (
        <tr>
          <td colSpan={3} className="show-more">
            <button
              type="button"
              className="btn btn--secondary btn--full-width btn--show-more-bottom btn--show-more-bottom-down"
              onClick={() => {
                dispatch(
                  groupRidesActions.rides({
                    id: id,
                    page: pagination.page + 1,
                    limit: pagination.limit,
                  }),
                );
              }}
            >
              Show More
            </button>
          </td>
        </tr>
      );
    }
  };

  const renderShowLess = () => {
    if (rides.loading) {
      return (
        <tr>
          <td colSpan={3} className="show-more">
            <button
              type="button"
              className="btn btn--secondary btn--full-width btn--show-more-bottom"
            >
              Loading
            </button>
          </td>
        </tr>
      );
    }

    if (pagination.page > 0) {
      return (
        <tr>
          <td colSpan={3} className="show-more">
            <button
              type="button"
              className="btn btn--secondary btn--full-width btn--show-more-top"
              onClick={() => {
                dispatch(
                  groupRidesActions.rides({
                    id: id,
                    page: pagination.page - 1,
                    limit: pagination.limit,
                  }),
                );
              }}
            >
              Show More
            </button>
          </td>
        </tr>
      );
    }
  };

  return (
    //{console.log('rides')}
    <div className="tab-content__pane" id="rides">
      <table className="data-table">
        <thead>
          <tr>
            <th className="sort">
              <a href="/">Rank</a>
            </th>
            <th>
              <a href="/">Driver</a>,<a href="/">Year</a>,<a href="/">Model</a>,
              <a href="/">Trim</a>
            </th>
            <th className="text-left">
              <a href="/">Miles</a>
            </th>
          </tr>
        </thead>
        {renderBody()}
      </table>
    </div>
  );
});
