import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { groupMembersSaga } from './saga';
import { GroupMembersState } from './types';

export const initialState: GroupMembersState = {
  members: {
    loading: true,
    data: [],
    error: null,
  },
  showMore: {
    loading: false,
    error: null,
  },
  pagination: {
    page: 0,
    limit: 20,
    totalPages: 0,
  },
  removeMember: {
    success: false,
    loading: false,
    error: null,
  },
  promoteToMember: {
    success: false,
    loading: false,
    error: null,
  },
  promoteToManager: {
    success: false,
    loading: false,
    error: null,
  },
  demoteToMember: {
    success: false,
    loading: false,
    error: null,
  },
  ignoreRequest: {
    success: false,
    loading: false,
    error: null,
  },
  invite: {
    success: false,
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'groupMembers',
  initialState,
  reducers: {
    members(state, action: PayloadAction<any>) {
      state.members.loading = true;
      state.members.error = null;
      state.members.data = [];
    },
    setMembers(state, action: PayloadAction<any>) {
      state.members.data = action.payload;
    },
    membersSuccess(state, action: PayloadAction<any>) {
      state.members.data = action.payload.members;
      state.pagination = action.payload.pagination;
      state.members.loading = false;
    },
    membersError(state, action: PayloadAction<any>) {
      state.members.loading = false;
      state.members.error = action.payload;
      state.members.data = [];
    },
    showMore(state, action: PayloadAction<any>) {
      state.showMore.loading = true;
      state.showMore.error = false;
    },
    showMoreSuccess(state, action: PayloadAction<any>) {
      state.showMore.loading = false;
      state.members.data = [...state.members.data, ...action.payload.members];
      state.pagination = action.payload.pagination;
    },
    showMoreError(state, action: PayloadAction<any>) {
      state.showMore.loading = false;
      state.showMore.error = action.payload;
    },
    removeMember(state, action: PayloadAction<any>) {
      state.removeMember.loading = true;
      state.removeMember.error = null;
    },
    removeMemberSuccess(state, action: PayloadAction<any>) {
      state.removeMember.loading = false;
      state.removeMember.success = action.payload;
    },
    removeMemberError(state, action: PayloadAction<any>) {
      state.removeMember.loading = false;
      state.removeMember.error = action.payload;
    },
    resetRemoveMember(state) {
      state.removeMember = {
        success: false,
        loading: false,
        error: null,
      };
    },
    promoteToMember(state, action: PayloadAction<any>) {
      state.promoteToMember.loading = true;
      state.promoteToMember.error = null;
    },
    promoteToMemberSuccess(state, action: PayloadAction<any>) {
      state.promoteToMember.loading = false;
      state.promoteToMember.success = action.payload;
    },
    promoteToMemberError(state, action: PayloadAction<any>) {
      state.promoteToMember.loading = false;
      state.promoteToMember.error = action.payload;
    },
    resetPromoteMember(state) {
      state.promoteToMember = {
        success: false,
        loading: false,
        error: null,
      };
    },
    promoteToManager(state, action: PayloadAction<any>) {
      state.promoteToManager.loading = true;
      state.promoteToManager.error = null;
    },
    promoteToManagerSuccess(state, action: PayloadAction<any>) {
      state.promoteToManager.loading = false;
      state.promoteToManager.success = action.payload;
    },
    promoteToManagerError(state, action: PayloadAction<any>) {
      state.promoteToManager.loading = false;
      state.promoteToManager.error = action.payload;
    },
    resetPromoteToManager(state) {
      state.promoteToManager = {
        success: false,
        loading: false,
        error: null,
      };
    },
    demoteToMember(state, action: PayloadAction<any>) {
      state.demoteToMember.loading = true;
      state.demoteToMember.error = null;
    },
    demoteToMemberSuccess(state, action: PayloadAction<any>) {
      state.demoteToMember.loading = false;
      state.demoteToMember.success = action.payload;
    },
    demoteToMemberError(state, action: PayloadAction<any>) {
      state.demoteToMember.loading = false;
      state.demoteToMember.error = action.payload;
    },
    resetDemoteToMember(state) {
      state.demoteToMember = {
        success: false,
        loading: false,
        error: null,
      };
    },
    ignoreRequest(state, action: PayloadAction<any>) {
      state.ignoreRequest.loading = true;
      state.ignoreRequest.error = null;
    },
    ignoreRequestSuccess(state, action: PayloadAction<any>) {
      state.ignoreRequest.loading = false;
      state.ignoreRequest.success = action.payload;
    },
    ignoreRequestError(state, action: PayloadAction<any>) {
      state.ignoreRequest.loading = false;
      state.ignoreRequest.error = action.payload;
    },
    resetIgnoreRequest(state) {
      state.ignoreRequest = {
        success: false,
        loading: false,
        error: null,
      };
    },
    invite(state, action: PayloadAction<any>) {
      state.invite.loading = true;
      state.invite.error = null;
    },
    inviteSuccess(state, action: PayloadAction<any>) {
      state.invite.loading = false;
      state.invite.success = action.payload;
    },
    inviteError(state, action: PayloadAction<any>) {
      state.invite.loading = false;
      state.invite.error = action.payload;
    },
    resetInvite(state) {
      state.invite = {
        success: false,
        loading: false,
        error: null,
      };
    },
  },
});

export const { actions: groupMembersActions } = slice;

export const useGroupMembersSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: groupMembersSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGroupMembersSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
