import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.group || initialState;

export const selectGroups = createSelector(
  [selectSlice],
  state => state.groups,
);

export const selectPagination = createSelector(
  [selectSlice],
  state => state.pagination,
);

export const selectShowMore = createSelector(
  [selectSlice],
  state => state.showMore,
);

export const selectMemberships = createSelector(
  [selectSlice],
  state => state.memberships,
);
