import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { groupsChatSaga } from './saga';
import { GroupsChatState } from './types';

export const initialState: GroupsChatState = {
  messages: {
    loading: true,
    data: [],
    error: null,
  },
  createMessage: {
    loading: false,
    success: null,
    error: null,
  },
  pagination: {
    limit: 5,
    next: null,
  },
  showMore: {
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'groupsChat',
  initialState,
  reducers: {
    setMessageAtBeggining(state, action: PayloadAction<any>) {
      state.messages.data.unshift(action.payload);
    },
    messages(state, action: PayloadAction<any>) {
      state.messages.loading = true;
      state.messages.error = null;
      state.messages.data = [];
    },
    messagesSuccess(state, action: PayloadAction<any>) {
      state.messages.data = action.payload.messages;
      state.pagination = action.payload.pagination;
      state.messages.loading = false;
    },
    messagesError(state, action: PayloadAction<any>) {
      state.messages.loading = false;
      state.messages.error = action.payload;
      state.messages.data = [];
    },
    showMore(state, action: PayloadAction<any>) {
      state.showMore.loading = true;
      state.showMore.error = false;
    },
    showMoreSuccess(state, action: PayloadAction<any>) {
      state.showMore.loading = false;
      state.messages.data = [
        ...state.messages.data,
        ...action.payload.messages,
      ];
      state.pagination = action.payload.pagination;
    },
    showMoreError(state, action: PayloadAction<any>) {
      state.showMore.loading = false;
      state.showMore.error = action.payload;
    },
    createMessage(state, action: PayloadAction<any>) {
      state.createMessage.loading = true;
      state.createMessage.error = null;
      state.createMessage.success = false;
    },
    createMessageSuccess(state, action: PayloadAction<any>) {
      state.createMessage.loading = false;
      state.createMessage.success = action.payload;
    },
    createMessageError(state, action: PayloadAction<any>) {
      state.createMessage.loading = false;
      state.createMessage.error = action.payload;
      state.createMessage.success = false;
    },
    resetCreateMessage(state) {
      state.createMessage.loading = false;
      state.createMessage.error = null;
      state.createMessage.success = null;
    },
  },
});

export const { actions: groupsChatActions } = slice;

export const useGroupsChatSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: groupsChatSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useGroupsChatSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
