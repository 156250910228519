import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ChangePhoneSaga } from './saga';
import { ChangePhoneState } from './types';

export const initialState: ChangePhoneState = {
  sendOtpToChangePhone: {
    loading: false,
    success: null,
    error: null,
  },
};

const slice = createSlice({
  name: 'ChangePhone',
  initialState,
  reducers: {
    sendOtpToChangePhone(state, action: PayloadAction<any>) {
      state.sendOtpToChangePhone.loading = true;
      state.sendOtpToChangePhone.error = null;
      state.sendOtpToChangePhone.success = false;
    },
    sendOtpToChangePhoneSuccess(state, action: PayloadAction<any>) {
      state.sendOtpToChangePhone.loading = false;
      state.sendOtpToChangePhone.success = action.payload;
    },
    sendOtpToChangePhoneError(state, action: PayloadAction<any>) {
      state.sendOtpToChangePhone.loading = false;
      state.sendOtpToChangePhone.error = action.payload;
      state.sendOtpToChangePhone.success = false;
    },
    resetSendOtpToChangePhone(state) {
      state.sendOtpToChangePhone.loading = false;
      state.sendOtpToChangePhone.error = null;
      state.sendOtpToChangePhone.success = null;
    },
  },
});

export const { actions: changePhoneActions } = slice;

export const useChangePhoneSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: ChangePhoneSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useChangePhoneSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
