import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.myGarage || initialState;

export const selectMyGarage = createSelector([selectSlice], state => state);

export const selectUserGroups = createSelector(
  [selectSlice],
  state => state.userGroups,
);

export const selectUserRides = createSelector(
  [selectSlice],
  state => state.userRides,
);

export const selectUserBadges = createSelector(
  [selectSlice],
  state => state.userBadges,
);
