import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.manageGroup || initialState;

export const selectManageGroup = createSelector(
  [selectSlice],
  state => state.manageGroup,
);
export const selectDeleteGroup = createSelector(
  [selectSlice],
  state => state.deleteGroup,
);
