/**
 *
 * Asynchronously loads the component for MyGaragePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const MyGaragePage = lazyLoad(
  () => import('./index'),
  module => module.MyGaragePage,
);
