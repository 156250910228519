import { Modal } from 'app/components/Modal';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { manageGroupActions } from '../slice';
import { selectDeleteGroup } from '../slice/selectors';

interface Props {
  active: boolean;
  onClose: any;
  id: string;
}

export const ConfirmDeleteGroupModal = memo((props: Props) => {
  const { active, onClose, id } = props;
  const deleteGroup = useSelector(selectDeleteGroup);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (deleteGroup.success) {
      dispatch(manageGroupActions.resetDeleteGroup());
      navigate(`/groups`);
    }
  }, [deleteGroup]);
  return (
    <Modal active={active} onClose={onClose}>
      <div
        className="modal__body"
        style={{
          boxSizing: 'border-box',
          borderRadius: '15px',
          textAlign: 'center',
        }}
      >
        Are you really sure you want to delete this group? This is PERMANENT!
      </div>
      <div className="modal__actions flow-spacer-medium">
        <button
          type="button"
          className="btn btn--primary-gradient"
          style={{ marginRight: '1.75rem', marginTop: '2.5rem' }}
          onClick={() => {
            dispatch(manageGroupActions.deleteGroup(id));
          }}
        >
          {deleteGroup.loading ? 'Loading...' : 'Yes, Delete It FOREVER'}
        </button>
        <button
          type="button"
          className="btn btn--primary-gradient"
          style={{ marginTop: '2.5rem' }}
          onClick={() => {
            onClose();
          }}
        >
          Oops - Don't Delete It
        </button>
      </div>
    </Modal>
  );
});
