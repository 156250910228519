import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import { request } from 'utils/request';
import { groupActions as actions } from '.';

function* groups(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/groups?page=${action.payload.page}&search=${action.payload.search}&limit=${action.payload.limit}`;
    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    yield put(
      actions.groupsSuccess({
        groups: response.groups,
        pagination: response.pagination,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.groupsError(true));
  }
}

function* showMore(action: PayloadAction<any>) {
  try {
    let requestURL = `/api/v1/groups?page=${action.payload.page}&search=${action.payload.search}&limit=${action.payload.limit}`;
    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    yield put(
      actions.showMoreSuccess({
        groups: response.groups,
        pagination: response.pagination,
      }),
    );
  } catch (err) {
    console.log(err);
    yield put(actions.showMoreError(true));
  }
}
function* memberships() {
  try {
    let requestURL = `/api/v1/memberships`;
    let response = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    yield put(actions.membershipsSuccess(response.memberships));
  } catch (err) {
    console.log(err);
    yield put(actions.membershipsError(true));
  }
}

export function* groupSaga() {
  yield takeLatest(actions.groups.type, groups);
  yield takeLatest(actions.showMore.type, showMore);
  yield takeLatest(actions.memberships.type, memberships);
}
