/**
 *
 * GroupDetailsPage
 *
 */
import { Breadcrumb } from 'app/components/Breadcrumb';
import { Button } from 'app/components/Button';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { GroupMembers } from '../GroupMembers';
import { useGroupMembersSlice } from '../GroupMembers/slice';
import { GroupRides } from '../GroupRides';
import { useGroupRidesSlice } from '../GroupRides/slice';
import { GroupsChat } from '../GroupsChat';
import { useGroupsChatSlice } from '../GroupsChat/slice';
import {
  selectIsAuthenticated,
  selectUserData,
} from '../HomePage/slice/selectors';
import { Detail } from './Components/Detail';
import { groupDetailsActions } from './slice';
import {
  selectAcceptInvite,
  selectGroup,
  selectGroupData,
  selectJoinGroup,
  selectLeaveGroup,
  selectRequestGroup,
  alert,
} from './slice/selectors';
import { LeaveOwnerGroupModal } from './Components/LeaveOwnerGroupModal';
import { LeaveGroup } from './Components/LeaveGroup';
import { selectRides } from '../GroupRides/slice/selectors';
import PowerGroup from 'assets/svg/badges/Power_Group.svg';
import PremierGroup from 'assets/svg/badges/Premier_Group.svg';
import { selectPagination } from '../GroupRides/slice/selectors';

//import { selectRideData } from '../GroupRides/slice/selectors';

interface Props {}

export const GroupDetailsPage = memo((props: Props) => {
  useGroupRidesSlice();
  useGroupMembersSlice();
  useGroupsChatSlice();
  const dispatch = useDispatch();
  const group = useSelector(selectGroup);
  const groupData = useSelector(selectGroupData);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const joinGroup = useSelector(selectJoinGroup);
  const leaveGroup = useSelector(selectLeaveGroup);
  const requestGroup = useSelector(selectRequestGroup);
  const acceptInvite = useSelector(selectAcceptInvite);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const user = useSelector(selectUserData);
  const addAlert = useSelector(alert);
  const rides = useSelector(selectRides);
  const pagination = useSelector(selectPagination);

  let active = params['*'];

  useEffect(() => {
    let acceptInvite: any = false;
    if (location.search) {
      const params = new URLSearchParams(location.search);
      acceptInvite = Boolean(params.get('accept-invite')) || false;
    }

    if (acceptInvite) {
      dispatch(groupDetailsActions.acceptInvite(params.id));
    } else {
      dispatch(groupDetailsActions.group(params.id));
    }
  }, []);

  useEffect(() => {
    if (joinGroup.success) {
      dispatch(groupDetailsActions.setGroup(joinGroup.success));
      dispatch(groupDetailsActions.resetJoinGroup());
    }
  }, [joinGroup.success]);

  useEffect(() => {
    if (leaveGroup.success) {
      dispatch(groupDetailsActions.resetLeaveGroup());
      navigate('/groups');
    }
  }, [leaveGroup]);

  useEffect(() => {
    if (requestGroup.success) {
      dispatch(groupDetailsActions.setGroup(requestGroup.success));
      dispatch(groupDetailsActions.resetRequestGroup());
    }
  }, [requestGroup]);

  useEffect(() => {
    if (acceptInvite.success) {
      dispatch(groupDetailsActions.group(params.id));
      dispatch(groupDetailsActions.resetAcceptInvite());
      // remove query param from url
      navigate(`/groups/${params.id}`);
    }
    if (acceptInvite.error) {
      dispatch(groupDetailsActions.group(params.id));
      dispatch(groupDetailsActions.resetAcceptInvite());
      navigate(`/groups/${params.id}`);
    }
  }, [acceptInvite]);

  let tabCondition = useMemo(() => {
    return (
      (groupData.isMember || groupData.isManager || groupData.isOwner) &&
      isAuthenticated
    );
  }, [groupData, isAuthenticated]);

  const [modal, setModal] = useState('');

  const renderTab = () => {
    return (
      <Routes>
        <Route path="" element={<Detail />} />
        <Route
          path="chat"
          element={<GroupsChat tabCondition={tabCondition} />}
        />
        <Route
          path="members"
          element={<GroupMembers tabCondition={tabCondition} />}
        />
        <Route
          path="rides"
          element={<GroupRides tabCondition={tabCondition} />}
        />
      </Routes>
    );
  };

  const renderDescription = () => {
    if (groupData.description) {
      return (
        <div className="card card--padded">
          <p>{groupData.description}</p>
        </div>
      );
    }
  };

  const renderImageAchivement = (badge: any) => {
    if (badge) {
      let image = getImage(badge.name);
      if (image) {
        return (
          <img
            src={image}
            alt={badge.name}
            className="badge__icon"
            style={{
              padding: '0.1rem',
              objectFit: 'contain',
              width: '100%',
            }}
          />
        );
      }
      return null;
    }
    return null;
  };

  const renderBadges = (badges: any) => {
    return badges.map(badge => {
      return (
        <div className="badge" key={badge._id}>
          <div className="badge__image">{renderImageAchivement(badge)}</div>
          <div className="badge__name"> {badge.title}</div>
          <div className="badge__description" style={{ whiteSpace: 'nowrap' }}>
            {badge.description}
          </div>
        </div>
      );
    });
  };

  const renderBadgesWrapper = () => {
    let badges: any = [];

    for (let i = 0; i < groupData.badges.length; i++) {
      badges.push(groupData.badges[i]);
    }

    if (badges.length === 0) {
      return <div></div>;
    }
    if (badges.length === 1) {
      return <div className="badges single">{renderBadges(badges)}</div>;
    }
    return <div className="badges">{renderBadges(badges)}</div>;
  };

  const renderAchievements = () => {
    return (
      <section>
        {/*<h3 className="text-center">Achievements</h3>*/}
        {renderBadgesWrapper()}
      </section>
    );
  };

  const getImage = (name: string) => {
    if (name === 'Power Group') {
      return PowerGroup;
    }
    if (name === 'Premier Group') {
      return PremierGroup;
    }
    return null;
  };

  const renderActions = () => {
    if (!isAuthenticated || !user.isEmailVerified) {
      return (
        <div className="cta cta--page">
          <div
            className="btn btn--primary-gradient btn--small"
            onClick={() => {
              dispatch(groupDetailsActions.joinGroup(groupData._id));
            }}
            role="presentation"
          >
            <Link
              to={'/login'}
              style={{ textDecoration: 'none' }}
              state={location.pathname}
            >
              {joinGroup.loading ? 'Loading...' : 'Sign In to Join a Group'}
            </Link>
          </div>
        </div>
      );
    }
    if (groupData.isOwner) {
      return (
        <div className="cta cta--page">
          <button
            onClick={() => {
              navigate('manage');
            }}
            className="btn btn--primary-gradient btn--small"
          >
            Manage Group
          </button>
          <Button
            className="btn btn--primary-gradient btn--small"
            onClick={() => {
              setModal('leaveOwnerGroup');
            }}
            role="presentation"
            disabled={groupData.memberCount <= 1}
          >
            {leaveGroup.loading ? 'Loading...' : 'Leave Group'}
          </Button>
        </div>
      );
    }
    if (groupData.isInvited) {
      return (
        <div className="cta cta--page">
          <div
            className="btn btn--primary-gradient btn--small"
            onClick={() => {
              dispatch(groupDetailsActions.acceptInvite(groupData._id));
            }}
            role="presentation"
          >
            {acceptInvite.loading ? 'Loading...' : 'Accept Invitation'}
          </div>
        </div>
      );
    }
    if (
      !(groupData.isMember || groupData.isManager) &&
      groupData.membership.name === 'open'
    ) {
      return (
        <div className="cta cta--page">
          <div
            className="btn btn--primary-gradient btn--small"
            onClick={() => {
              dispatch(groupDetailsActions.joinGroup(groupData._id));
            }}
            role="presentation"
          >
            {joinGroup.loading ? 'Loading...' : 'Join Group'}
          </div>
        </div>
      );
    }
    if (groupData.isRequested) {
      return (
        <div className="cta cta--page">
          <div className="btn btn--primary-gradient btn--small">Requested</div>
        </div>
      );
    }

    if (!groupData.isMember && groupData.membership.name === 'request') {
      return (
        <div className="cta cta--page">
          <div
            className="btn btn--primary-gradient btn--small"
            onClick={() => {
              dispatch(groupDetailsActions.requestGroup(groupData._id));
            }}
            role="presentation"
          >
            {requestGroup.loading ? 'Loading...' : 'Request to join Group'}
          </div>
        </div>
      );
    }
    if (groupData.isMember || groupData.isManager) {
      return (
        <div className="cta cta--page">
          <div
            className="btn btn--primary-gradient btn--small"
            onClick={() => {
              setModal('leaveGroup');
            }}
            role="presentation"
          >
            Leave Group
          </div>
        </div>
      );
    }
    return <></>;
  };

  const numberOfRides = () => {
    for (let i = 0; i < groupData.groupRides.length; i++) {
      if (groupData.groupRides[i].name === groupData.name) {
        return groupData.groupRides[i].ridesCount;
      }
    }
  };

  const renderSection = () => {
    if (group.loading) {
      return <div>Loading...</div>;
    }
    if (group.error) {
      return <div>Error</div>;
    }
    return (
      <section>
        <h2>{groupData.name}</h2>
        {renderDescription()}
        <ul className="tabs" data-tab-content-container="groupDetailsTab">
          <li className="tabs__item">
            <Link
              to=""
              type="button"
              className={`btn btn--tab ${active === '' ? 'active' : ''}`}
              data-tab-content="group"
            >
              Group
            </Link>
          </li>
          {tabCondition ? (
            <li className="tabs__item">
              <Link
                to="chat"
                type="button"
                className={`btn btn--tab ${active === 'chat' ? 'active' : ''}`}
                data-tab-content="chat"
              >
                Chat
              </Link>
            </li>
          ) : null}
          {/*{tabCondition ? (*/}
          <li className="tabs__item">
            <Link
              to="members"
              type="button"
              className={`btn btn--tab ${active === 'members' ? 'active' : ''}`}
              data-tab-content="members"
              data-count={
                groupData.requestedMembersCount &&
                (groupData.isOwner || groupData.isManager)
                  ? groupData.requestedMembersCount
                  : null
              }
            >
              Members ({groupData.memberCount})
            </Link>
          </li>
          {/*) : null}*/}
          {/*{tabCondition ? (*/}
          <li className="tabs__item">
            <Link
              to="rides"
              type="button"
              className={`btn btn--tab ${active === 'rides' ? 'active' : ''}`}
              data-tab-content="rides"
              data-count={groupData.ridesCount}
            >
              Rides ({numberOfRides()})
            </Link>
          </li>
          {/*) : null}*/}
        </ul>
        <div className="tab-content" id="groupDetailsTab">
          {renderTab()}
        </div>
        {/*
        {groupData.badges.length > 0 ? renderAchievements() : '' }*/}

        {/*{renderBadgesWrapper()}*/}
        <br />
        <br />
        {renderActions()}
      </section>
    );
  };
  const onClose = () => {
    setModal('');
  };

  return (
    <main className="main">
      <div className={`acknowledgment ${addAlert.success ? 'show' : ''}`}>
        <div className="acknowledgment__text">
          You have left the group GroupName.
        </div>
      </div>
      <Breadcrumb
        links={[
          {
            label: 'Groups',
            to: '/groups',
          },
        ]}
      />
      {renderSection()}
      <LeaveOwnerGroupModal
        active={modal === 'leaveOwnerGroup'}
        onClose={onClose}
      />
      <LeaveGroup active={modal === 'leaveGroup'} onClose={onClose} />
    </main>
  );
});
