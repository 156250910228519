import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { myGarageSaga } from './saga';
import { MyGarageState } from './types';

export const initialState: MyGarageState = {
  userGroups: {
    data: [],
    loading: false,
    error: null,
  },
  userRides: {
    data: [],
    loading: false,
    error: null,
  },
  userBadges: {
    data: [],
    loading: false,
    error: null,
  },
};

const slice = createSlice({
  name: 'myGarage',
  initialState,
  reducers: {
    userGroups(state, action: PayloadAction<any>) {
      state.userGroups.loading = true;
      state.userGroups.error = null;
      state.userGroups.data = [];
    },
    userGroupsSuccess(state, action: PayloadAction<any>) {
      state.userGroups.data = action.payload;
      state.userGroups.loading = false;
    },
    userGroupsError(state, action: PayloadAction<any>) {
      state.userGroups.loading = false;
      state.userGroups.error = action.payload;
      state.userGroups.data = [];
    },
    userRides(state, action: PayloadAction<any>) {
      state.userRides.loading = true;
      state.userRides.error = null;
      state.userRides.data = [];
    },
    userRidesSuccess(state, action: PayloadAction<any>) {
      state.userRides.data = action.payload;
      state.userRides.loading = false;
    },
    userRidesError(state, action: PayloadAction<any>) {
      state.userRides.loading = false;
      state.userRides.error = action.payload;
      state.userRides.data = [];
    },
    userBadges(state, action: PayloadAction<any>) {
      state.userBadges.loading = true;
      state.userBadges.error = null;
      state.userBadges.data = [];
    },
    userBadgesSuccess(state, action: PayloadAction<any>) {
      state.userBadges.data = action.payload;
      state.userBadges.loading = false;
    },
    userBadgesError(state, action: PayloadAction<any>) {
      state.userBadges.loading = false;
      state.userBadges.error = action.payload;
      state.userBadges.data = [];
    },
  },
});

export const { actions: myGarageActions } = slice;

export const useMyGarageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: myGarageSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useMyGarageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
