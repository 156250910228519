import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { createGroupSaga } from './saga';
import { CreateGroupState } from './types';

export const initialState: CreateGroupState = {
  createGroup: {
    loading: false,
    success: null,
    error: null,
  },
};

const slice = createSlice({
  name: 'createGroup',
  initialState,
  reducers: {
    createGroup(state, action: PayloadAction<any>) {
      state.createGroup.loading = true;
      state.createGroup.error = null;
      state.createGroup.success = false;
    },
    createGroupSuccess(state, action: PayloadAction<any>) {
      state.createGroup.loading = false;
      state.createGroup.success = action.payload;
    },
    createGroupError(state, action: PayloadAction<any>) {
      state.createGroup.loading = false;
      state.createGroup.error = action.payload;
      state.createGroup.success = false;
    },
    resetCreateGroup(state) {
      state.createGroup.loading = false;
      state.createGroup.error = null;
      state.createGroup.success = null;
    },
  },
});

export const { actions: createGroupActions } = slice;

export const useCreateGroupSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: createGroupSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useCreateGroupSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
