import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { homePageSaga } from './saga';
import { HomePageState } from './types';

export const initialState: HomePageState = {
  user: {
    data: null,
    loading: true,
    error: false,
  },
  isAuthenticated: false,
  searchZip: {
    loading: false,
    data: null,
    error: null,
  },
};

const slice = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    getUser(state) {
      state.user.loading = true;
      state.user.error = null;
    },
    getUserSuccess(state, action: PayloadAction<any>) {
      state.user.data = action.payload;
      state.user.loading = false;
      state.isAuthenticated = true;
    },
    getUserError(state, action: PayloadAction<any>) {
      state.user.error = action.payload;
      state.user.loading = false;
      state.isAuthenticated = false;
    },
    logout(state) {
      localStorage.clear();
      state.user.data = null;
      state.isAuthenticated = false;
    },
    setUser(state, action: PayloadAction<any>) {
      state.user.data = action.payload;
      state.isAuthenticated = true;
    },
    searchZip(state, action: PayloadAction<any>) {
      state.searchZip.loading = true;
      state.searchZip.error = null;
      state.searchZip.data = null;
    },
    searchZipSuccess(state, action: PayloadAction<any>) {
      state.searchZip.loading = false;
      state.searchZip.data = action.payload;
    },
    searchZipError(state, action: PayloadAction<any>) {
      state.searchZip.loading = false;
      state.searchZip.error = action.payload;
      state.searchZip.data = false;
    },
    resetSearchZip(state) {
      state.searchZip.loading = false;
      state.searchZip.error = false;
      state.searchZip.data = null;
    },
  },
});

export const { actions: homePageActions } = slice;

export const useHomePageSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: homePageSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useHomePageSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
