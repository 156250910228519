/**
 *
 * Asynchronously loads the component for ChangeEmail
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ChangeEmail = lazyLoad(
  () => import('./index'),
  module => module.ChangeEmail,
);
