/**
 *
 * Modal
 *
 */
import React, { memo, useEffect } from 'react';

interface Props {
  active: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export const Modal = memo((props: Props) => {
  const { active, children, onClose } = props;

  useEffect(() => {
    const handleClick = (e: any) => {
      if (e.target.classList.contains('modal')) {
        onClose();
      }
    };
    if (active) {
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [active, onClose]);

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [active, onClose]);

  return (
    <div className={`modal ${active ? 'modal--active' : ''}`}>
      <div className="modal__content">{children}</div>
    </div>
  );
});
