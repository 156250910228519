/**
 *
 * Asynchronously loads the component for NotificationSettingsPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const NotificationSettingsPage = lazyLoad(
  () => import('./index'),
  module => module.NotificationSettingsPage,
);
