import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.groupMembers || initialState;

export const selectMembers = createSelector(
  [selectSlice],
  state => state.members,
);

export const selectPagination = createSelector(
  [selectSlice],
  state => state.pagination,
);

export const selectShowMore = createSelector(
  [selectSlice],
  state => state.showMore,
);

export const selectRemoveMember = createSelector(
  [selectSlice],
  state => state.removeMember,
);

export const selectPromoteToMember = createSelector(
  [selectSlice],
  state => state.promoteToMember,
);
export const selectPromoteToManager = createSelector(
  [selectSlice],
  state => state.promoteToManager,
);

export const selectDemoteToMember = createSelector(
  [selectSlice],
  state => state.demoteToMember,
);

export const selectIgnoreRequest = createSelector(
  [selectSlice],
  state => state.ignoreRequest,
);
export const selectInvite = createSelector(
  [selectSlice],
  state => state.invite,
);
